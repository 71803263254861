import React, { useState } from 'react';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import {
    AccentLabel,
    AnimatedPageLoader,
    Button,
    ButtonSecondary,
    Checkbox,
    HorizontalFlex,
    PadContainer,
    Title,
    VerticalFlex,
} from 'smartsuite-ui';

import BagTicket from '../components/BagTicket';
import ContactInformation from '../components/ContactInformation';
import ErrorPage from '../components/ErrorPage';
import FormWrapper from '../components/FormWrapper';
import { customsFormOptions, views } from '../constants/claimConstants';
import { useClaimContext } from '../contexts/ClaimContext';
import claimLabels from '../locales/en/claimLabels';

function ClaimInformation({
    customsFormDisplayPage,
    passengerData,
    setCurrentView,
    location,
}) {
    const { submitClaimResponse, isFetching, updateClaim } = useClaimContext();
    const [selectedBags, setSelectedBags] = useState([]);
    const [selectedPassengers, setSelectedPassengers] = useState([]);

    const canFileClaim = passengerData && !submitClaimResponse;
    const fileNumber = submitClaimResponse
        ? submitClaimResponse.tracerRecordReferenceNumber
        : null;

    function onTagNumberChange(isChecked, bag, passenger) {
        setSelectedBags((prevState) => {
            if (isChecked) {
                return [...prevState, bag];
            } else {
                return prevState.filter(
                    (stateBag) => stateBag.tagNumber !== bag.tagNumber
                );
            }
        });
        setSelectedPassengers((prevState) => {
            if (isChecked) {
                const passengerAlreadyAdded = prevState.find(
                    (statePassenger) =>
                        statePassenger.passengerName === passenger.passengerName
                );
                if (passengerAlreadyAdded) {
                    return prevState;
                }
                return [...prevState, passenger];
            } else {
                return prevState.filter(
                    (statePassenger) =>
                        statePassenger.passengerName !== passenger.passengerName
                );
            }
        });
    }

    function onArrangeDelivery() {
        updateClaim({ bags: selectedBags, passengers: selectedPassengers });
        setCurrentView(views.bagInformation);
    }

    if (isFetching) {
        return (
            <VerticalFlex cssClassModifiers={['small-relative']}>
                <PadContainer>
                    <AnimatedPageLoader />
                </PadContainer>
            </VerticalFlex>
        );
    }

    if (submitClaimResponse && !submitClaimResponse.isSuccess) {
        return <ErrorPage goBack={() => location.history.goBack()} />;
    }

    function getInstruction() {
        if (!passengerData) {
            return claimLabels.instructionGeneralError;
        }

        if (submitClaimResponse) {
            return (
                <div>
                    {claimLabels.instructionPassengerSubmitted1}
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a href={claimLabels.contactLink} target="_blank">
                        {claimLabels.instructionPassengerSubmitted2}
                    </a>
                    .
                </div>
            );
        }

        return claimLabels.instructionSubmitClaim;
    }

    function getTitle() {
        if (!passengerData) {
            return claimLabels.titleGeneralError;
        }

        if (submitClaimResponse) {
            return claimLabels.titlePassengerSubmitted;
        }

        return claimLabels.titleSubmitClaim;
    }

    function renderPassengers() {
        if (!passengerData) {
            return <BagTicket />;
        }

        if (submitClaimResponse) {
            return (
                <div className="claim-information__passenger claim-information__passenger--submitted">
                    {submitClaimResponse.passengerNames.map((name) => (
                        <Title key={name} cssClassModifiers={['size-small']}>
                            {name}
                        </Title>
                    ))}
                    <VerticalFlex>
                        <HorizontalFlex>
                            <AccentLabel>
                                {claimLabels.bookingReference}
                            </AccentLabel>
                            <PadContainer cssClassModifiers={['pad-small']} />
                            <span className="claim-information__number">
                                {passengerData[0].passenger.pnr}
                            </span>
                        </HorizontalFlex>
                        {fileNumber && (
                            <HorizontalFlex>
                                <AccentLabel>
                                    {claimLabels.fileNumber}
                                </AccentLabel>
                                <PadContainer
                                    cssClassModifiers={['pad-small']}
                                />
                                <span className="claim-information__number">
                                    {fileNumber}
                                </span>
                            </HorizontalFlex>
                        )}
                    </VerticalFlex>
                </div>
            );
        }

        const bagsFromPassengers = passengerData
            .map(({ passenger, bags }) => {
                return bags.map((bag) => ({ ...bag, passenger }));
            })
            .flat();

        const sortedBags = orderBy(bagsFromPassengers, 'flightDate', 'desc');
        const bagsGroupedByDate = groupBy(sortedBags, 'flightDateFormatted');

        return map(bagsGroupedByDate, (bagGroup, flightDateFormatted) => {
            const bagsGroupedByPassenger = groupBy(bagGroup, 'passengerName');

            return (
                <div
                    key={flightDateFormatted}
                    className="claim-information__passenger-group"
                >
                    {map(bagsGroupedByPassenger, (bags, passengerName) => (
                        <div
                            key={flightDateFormatted + passengerName}
                            className="claim-information__passenger"
                        >
                            <Title cssClassModifiers={['size-small']}>
                                {passengerName}
                            </Title>
                            <VerticalFlex>
                                <HorizontalFlex>
                                    <AccentLabel>
                                        {claimLabels.bookingReference}
                                    </AccentLabel>
                                    <PadContainer
                                        cssClassModifiers={['pad-small']}
                                    />
                                    <span className="claim-information__number">
                                        {bags[0].passenger.pnr}
                                    </span>
                                    <PadContainer
                                        cssClassModifiers={['pad-small']}
                                    />
                                    <AccentLabel>
                                        {claimLabels.flightDate}
                                    </AccentLabel>
                                    <PadContainer
                                        cssClassModifiers={['pad-small']}
                                    />
                                    <span className="claim-information__number">
                                        {flightDateFormatted}
                                    </span>
                                </HorizontalFlex>
                                {fileNumber && (
                                    <HorizontalFlex>
                                        <AccentLabel>
                                            {claimLabels.fileNumber}
                                        </AccentLabel>
                                        <PadContainer
                                            cssClassModifiers={['pad-small']}
                                        />
                                        <span className="claim-information__number">
                                            {fileNumber}
                                        </span>
                                    </HorizontalFlex>
                                )}
                            </VerticalFlex>
                            {bags.map((bag) => (
                                <Checkbox
                                    key={bag.tagNumber}
                                    checkboxLabel={bag.tagNumber}
                                    onChange={(event) =>
                                        onTagNumberChange(
                                            event.target.checked,
                                            bag,
                                            bag.passenger
                                        )
                                    }
                                />
                            ))}
                        </div>
                    ))}
                </div>
            );
        });
    }

    function onBackButtonClick() {
        if (
            customsFormDisplayPage === customsFormOptions.reviewScreen &&
            !submitClaimResponse
        ) {
            setCurrentView(views.review);
        } else {
            location.history.goBack();
        }
    }

    function renderBody() {
        if (!canFileClaim) {
            return (
                <VerticalFlex>
                    <ContactInformation />
                    <PadContainer cssClassModifiers={['no-pad-left-right']}>
                        <ButtonSecondary onClick={onBackButtonClick}>
                            {claimLabels.back}
                        </ButtonSecondary>
                    </PadContainer>
                </VerticalFlex>
            );
        }

        return (
            <PadContainer cssClassModifiers={['no-pad-left-right']}>
                <HorizontalFlex cssClassModifiers={['space-between']}>
                    <ButtonSecondary onClick={onBackButtonClick}>
                        {claimLabels.back}
                    </ButtonSecondary>
                    <Button
                        disabled={selectedBags.length === 0}
                        onClick={onArrangeDelivery}
                    >
                        {claimLabels.reportBaggage}
                    </Button>
                </HorizontalFlex>
            </PadContainer>
        );
    }

    return (
        <FormWrapper instructions={getInstruction()} title={getTitle()}>
            {renderPassengers()}
            {renderBody()}
        </FormWrapper>
    );
}

export default ClaimInformation;
