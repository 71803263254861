import React, { useState } from 'react';
import {
    Button,
    ButtonSecondary,
    Checkbox,
    DateTimePicker,
    Fields,
    Input,
    PadContainer,
    SingleSelect,
    Title,
} from 'smartsuite-ui';
import FormFooter from '../components/FormFooter';
import FormWrapper from '../components/FormWrapper';
import { customsFormOptions, views } from '../constants/claimConstants';
import { countries } from '../constants/countries';
import { useClaimContext } from '../contexts/ClaimContext';
import claimLabels from '../locales/en/claimLabels';
import {
    getEmailErrorMessage,
    getPhoneErrorMessage,
    getStateErrorMessage,
    isAlpha,
    isAlphanumeric,
    isEmailLongEnough,
    isPhoneLongEnough,
    isPhoneValidCharacters,
    isStateLongEnough,
} from '../utils/validationUtil';

function isPhoneValid(value) {
    return isPhoneValidCharacters(value) && isPhoneLongEnough(value);
}

function isStateValid(value) {
    return isAlpha(value) && isStateLongEnough(value);
}

function AddressSelectionForm({ customsFormDisplayPage, setCurrentView }) {
    const { claim, submitClaim, updateClaim } = useClaimContext();
    const [address, setAddress] = useState(
        claim.isTemporaryAddress
            ? claim.temporaryAddress || {}
            : claim.permanentAddress || {}
    );
    const [secondaryAddress, setSecondaryAddress] = useState(
        claim.isTemporaryAddress ? claim.permanentAddress : {}
    );
    const [isTemporaryAddress, setIsTemporaryAddress] = useState(
        claim.isTemporaryAddress || false
    );

    function getClaimAddress() {
        if (isTemporaryAddress) {
            return {
                temporaryAddress: address,
                permanentAddress: secondaryAddress,
            };
        }
        return { permanentAddress: address, temporaryAddress: {} };
    }

    function onNavigate(view) {
        updateClaim({ ...getClaimAddress(), isTemporaryAddress });
        setCurrentView(view);
    }

    function onSubmit() {
        const fullClaim = {
            ...claim,
            ...getClaimAddress(),
            isTemporaryAddress,
        };
        submitClaim(fullClaim);
        setCurrentView(views.claimInformation);
    }

    function onChange(event) {
        const { id, value } = event.target;

        setAddress((prevState) => ({ ...prevState, [id]: value }));
    }

    function onSecondaryAddressChange(event) {
        const { id, value } = event.target;

        setSecondaryAddress((prevState) => ({ ...prevState, [id]: value }));
    }

    function onCountryChange(event) {
        setAddress((prevState) => ({
            ...prevState,
            country: event.valueField,
        }));
    }

    function onSecondaryCountryChange(event) {
        setSecondaryAddress((prevState) => ({
            ...prevState,
            country: event.valueField,
        }));
    }

    function onDateChange(event) {
        setAddress((prevState) => ({ ...prevState, validityDate: event }));
    }

    function isFormValid() {
        let isValid =
            address.lineOne &&
            address.city &&
            isAlpha(address.city) &&
            address.country &&
            address.postalCode &&
            address.state &&
            isStateValid(address.state) &&
            address.phoneNumber &&
            isPhoneValid(address.phoneNumber) &&
            isPhoneValid(address.cellNumber) &&
            isAlphanumeric(address.frequentFlyerNumber) &&
            address.email &&
            isEmailLongEnough(address.email);

        if (isTemporaryAddress) {
            isValid =
                isValid &&
                address.validityDate &&
                secondaryAddress.lineOne &&
                secondaryAddress.city &&
                isAlpha(secondaryAddress.city) &&
                secondaryAddress.country &&
                secondaryAddress.postalCode &&
                secondaryAddress.state &&
                isStateValid(secondaryAddress.state);
        }

        return isValid;
    }

    function renderNextButton() {
        if (customsFormDisplayPage === customsFormOptions.customsForm) {
            return (
                <Button
                    disabled={!isFormValid()}
                    onClick={() => onNavigate(views.customs)}
                >
                    {claimLabels.next}
                </Button>
            );
        }

        return (
            <Button disabled={!isFormValid()} onClick={onSubmit}>
                {claimLabels.submit}
            </Button>
        );
    }

    return (
        <FormWrapper
            instructions={claimLabels.instructionAddressSelectionForm}
            title={claimLabels.titleAddressSelectionForm}
        >
            <div className="form-fields">
                <Fields
                    cssClassModifiers={[
                        'column',
                        'no-background',
                        'no-pad-bottom',
                    ]}
                >
                    <PadContainer
                        cssClassModifiers={['pad-small', 'no-pad-top-bottom']}
                    >
                        <Title cssClassModifiers={['size-small']}>
                            {claimLabels.deliveryAddress}
                        </Title>
                    </PadContainer>
                    <Input
                        errorMessage={`${claimLabels.address} ${claimLabels.inputInvalid}`}
                        id="lineOne"
                        label={claimLabels.address}
                        maxLength={58}
                        onChange={onChange}
                        placeholder={claimLabels.address}
                        required="required"
                        value={address.lineOne || ''}
                    />
                </Fields>
                <Fields
                    cssClassModifiers={['no-background', 'no-pad-top-bottom']}
                >
                    <Input
                        cssClassModifiers={['half-relative']}
                        errorMessage={`${claimLabels.city} ${claimLabels.inputInvalid}`}
                        id="city"
                        isValid={isAlpha(address.city)}
                        label={claimLabels.city}
                        maxLength={27}
                        onChange={onChange}
                        placeholder={claimLabels.city}
                        required="required"
                        value={address.city || ''}
                    />
                    <SingleSelect
                        cssClassModifiers={['half-relative']}
                        isClearable={false}
                        label={claimLabels.country}
                        noOptionsMessage={() => claimLabels.noResults}
                        options={countries}
                        onChange={onCountryChange}
                        placeholder={claimLabels.country}
                        textField="textField"
                        value={countries.find(
                            (country) => country.valueField === address.country
                        )}
                        valueField="valueField"
                    />
                </Fields>
                <Fields
                    cssClassModifiers={['no-background', 'no-pad-top-bottom']}
                >
                    <Input
                        cssClassModifiers={['half-relative']}
                        errorMessage={`${claimLabels.postalCode} ${claimLabels.inputInvalid}`}
                        id="postalCode"
                        label={claimLabels.postalCode}
                        maxLength={12}
                        onChange={onChange}
                        placeholder={claimLabels.postalCode}
                        required="required"
                        value={address.postalCode || ''}
                    />
                    <Input
                        cssClassModifiers={['half-relative']}
                        errorMessage={getStateErrorMessage(
                            claimLabels,
                            address.state
                        )}
                        id="state"
                        isValid={isStateValid(address.state)}
                        label={claimLabels.state}
                        maxLength={26}
                        onChange={onChange}
                        placeholder={claimLabels.state}
                        required="required"
                        value={address.state || ''}
                    />
                </Fields>
                <Fields
                    cssClassModifiers={[
                        'column',
                        'no-background',
                        'no-pad-top',
                    ]}
                >
                    <Checkbox
                        checked={isTemporaryAddress}
                        onChange={(event) =>
                            setIsTemporaryAddress(event.target.checked)
                        }
                        checkboxLabel={claimLabels.isTemporary}
                    />
                    {isTemporaryAddress && (
                        <DateTimePicker
                            label={claimLabels.temporaryAddressValidUntil}
                            min={new Date()}
                            onChange={onDateChange}
                            time={false}
                            value={address.validityDate}
                        />
                    )}
                </Fields>
            </div>
            {isTemporaryAddress && (
                <div className="form-fields">
                    <Fields
                        cssClassModifiers={[
                            'column',
                            'no-background',
                            'no-pad-bottom',
                        ]}
                    >
                        <PadContainer
                            cssClassModifiers={[
                                'pad-small',
                                'no-pad-top-bottom',
                            ]}
                        >
                            <Title cssClassModifiers={['size-small']}>
                                {claimLabels.permanentAddress}
                            </Title>
                        </PadContainer>
                        <Input
                            errorMessage={`${claimLabels.address} ${claimLabels.inputInvalid}`}
                            id="lineOne"
                            label={claimLabels.address}
                            maxLength={58}
                            onChange={onSecondaryAddressChange}
                            placeholder={claimLabels.address}
                            required="required"
                            value={secondaryAddress.lineOne || ''}
                        />
                    </Fields>
                    <Fields
                        cssClassModifiers={[
                            'no-background',
                            'no-pad-top-bottom',
                        ]}
                    >
                        <Input
                            cssClassModifiers={['half-relative']}
                            errorMessage={`${claimLabels.city} ${claimLabels.inputInvalid}`}
                            id="city"
                            isValid={isAlpha(address.city)}
                            label={claimLabels.city}
                            maxLength={27}
                            onChange={onSecondaryAddressChange}
                            placeholder={claimLabels.city}
                            required="required"
                            value={secondaryAddress.city || ''}
                        />
                        <SingleSelect
                            cssClassModifiers={['half-relative']}
                            isClearable={false}
                            label={claimLabels.country}
                            noOptionsMessage={() => claimLabels.noResults}
                            options={countries}
                            onChange={onSecondaryCountryChange}
                            placeholder={claimLabels.country}
                            textField="textField"
                            value={countries.find(
                                (country) =>
                                    country.valueField ===
                                    secondaryAddress.country
                            )}
                            valueField="valueField"
                        />
                    </Fields>
                    <Fields cssClassModifiers={['no-background', 'no-pad-top']}>
                        <Input
                            cssClassModifiers={['half-relative']}
                            errorMessage={`${claimLabels.postalCode} ${claimLabels.inputInvalid}`}
                            id="postalCode"
                            label={claimLabels.postalCode}
                            maxLength={12}
                            onChange={onSecondaryAddressChange}
                            placeholder={claimLabels.postalCode}
                            required="required"
                            value={secondaryAddress.postalCode || ''}
                        />
                        <Input
                            cssClassModifiers={['half-relative']}
                            errorMessage={getStateErrorMessage(
                                claimLabels,
                                secondaryAddress.state
                            )}
                            id="state"
                            isValid={isStateValid(secondaryAddress.state)}
                            label={claimLabels.state}
                            maxLength={26}
                            onChange={onSecondaryAddressChange}
                            placeholder={claimLabels.state}
                            required="required"
                            value={secondaryAddress.state || ''}
                        />
                    </Fields>
                </div>
            )}

            <div className="form-fields">
                <Fields
                    cssClassModifiers={[
                        'column',
                        'no-background',
                        'no-pad-bottom',
                    ]}
                >
                    <PadContainer
                        cssClassModifiers={['pad-small', 'no-pad-top-bottom']}
                    >
                        <Title cssClassModifiers={['size-small']}>
                            {claimLabels.contactInformation}
                        </Title>
                    </PadContainer>
                    <PadContainer cssClassModifiers={['pad-small']}>
                        {claimLabels.instructionAddressSelectionFormContact}
                    </PadContainer>
                </Fields>
                <Fields
                    cssClassModifiers={['no-background', 'no-pad-top-bottom']}
                >
                    <Input
                        cssClassModifiers={['half-relative']}
                        errorMessage={getPhoneErrorMessage(
                            claimLabels,
                            'phoneNumber',
                            address.phoneNumber
                        )}
                        id="phoneNumber"
                        isValid={isPhoneValid(address.phoneNumber)}
                        label={claimLabels.phoneNumber}
                        maxLength={20}
                        onChange={onChange}
                        placeholder={claimLabels.phoneNumberPlaceholder}
                        required="required"
                        type="tel"
                        value={address.phoneNumber || ''}
                    />
                    <Input
                        cssClassModifiers={['half-relative']}
                        errorMessage={getPhoneErrorMessage(
                            claimLabels,
                            'cellNumber',
                            address.cellNumber
                        )}
                        id="cellNumber"
                        isValid={isPhoneValid(address.cellNumber)}
                        label={`${claimLabels.cellNumber} (${claimLabels.optional})`}
                        maxLength={20}
                        onChange={onChange}
                        placeholder={claimLabels.phoneNumberPlaceholder}
                        type="tel"
                        value={address.cellNumber || ''}
                    />
                </Fields>
                <Fields
                    cssClassModifiers={[
                        'column',
                        'no-background',
                        'no-pad-top',
                    ]}
                >
                    <Input
                        errorMessage={getEmailErrorMessage(
                            claimLabels,
                            address.email
                        )}
                        id="email"
                        isValid={isEmailLongEnough(address.email)}
                        label={claimLabels.email}
                        maxLength={48}
                        onChange={onChange}
                        placeholder={claimLabels.email}
                        required="required"
                        type="email"
                        value={address.email || ''}
                    />
                    <Input
                        errorMessage={`${claimLabels.frequentFlyerNumber} ${claimLabels.inputInvalid}`}
                        id="frequentFlyerNumber"
                        inputMode="numeric"
                        isValid={isAlphanumeric(address.frequentFlyerNumber)}
                        label={`${claimLabels.frequentFlyerNumber} (${claimLabels.optional})`}
                        maxLength={25}
                        onChange={onChange}
                        placeholder={claimLabels.frequentFlyerNumber}
                        value={address.frequentFlyerNumber || ''}
                    />
                </Fields>
            </div>
            <FormFooter
                navigateToPassengerInformation={() =>
                    setCurrentView(views.claimInformation)
                }
            >
                <ButtonSecondary
                    onClick={() => onNavigate(views.bagInformation)}
                >
                    {claimLabels.back}
                </ButtonSecondary>
                <PadContainer cssClassModifiers={['pad-small']} />
                {renderNextButton()}
            </FormFooter>
        </FormWrapper>
    );
}

export default AddressSelectionForm;
