import React from 'react';

export default function Passenger(props) {
    return (
        <div className="passenger">
            <div className="passenger__name-row">
                <p className="passenger__name">{props.passengerName}</p>
                {props.isBusinessClass && (
                    <p className="passenger__business-class">
                        {props.labels.businessClass}
                    </p>
                )}
            </div>
            <p className="passenger__PNR">{`${props.labels.bookingReference}: ${props.passengerPNR}`}</p>
        </div>
    );
}
