import artPortfolio from '../images/bagTypes/artPortfolio.svg';
import audioVideoEquipment from '../images/bagTypes/audioVideoEquipment.svg';
import babyCarriage from '../images/bagTypes/babyCarriage.svg';
import backpack from '../images/bagTypes/backpack.svg';
import baggageTrolley from '../images/bagTypes/baggageTrolley.svg';
import beddingBag from '../images/bagTypes/beddingBag.svg';
import bicycle from '../images/bagTypes/bicycle.svg';
import box from '../images/bagTypes/box.svg';
import briefcase from '../images/bagTypes/briefcase.svg';
import carSeat from '../images/bagTypes/carSeat.svg';
import computerEquipment from '../images/bagTypes/computerEquipment.svg';
import cooler from '../images/bagTypes/cooler.svg';
import cosmeticCase from '../images/bagTypes/cosmeticCase.svg';
import courierBag from '../images/bagTypes/courierBag.svg';
import diveBag from '../images/bagTypes/diveBag.svg';
import documentCase from '../images/bagTypes/documentCase.svg';
import duffel from '../images/bagTypes/duffel.svg';
import dutyFreeItems from '../images/bagTypes/dutyFreeItems.svg';
import electricalAppliance from '../images/bagTypes/electricalAppliance.svg';
import firearm from '../images/bagTypes/firearm.svg';
import fishingRod from '../images/bagTypes/fishingRod.svg';
import foldingChair from '../images/bagTypes/foldingChair.svg';
import garmentBag from '../images/bagTypes/garmentBag.svg';
import golfBag from '../images/bagTypes/golfBag.svg';
import hatBox from '../images/bagTypes/hatBox.svg';
import horizontalExpandable from '../images/bagTypes/horizontalExpandable.svg';
import horizontalHardshell from '../images/bagTypes/horizontalHardshell.svg';
import horizontalNonExpandable from '../images/bagTypes/horizontalNonExpandable.svg';
import horizontalSuitcase from '../images/bagTypes/horizontalSuitcase.svg';
import laptop from '../images/bagTypes/laptop.svg';
import mattedWovenBag from '../images/bagTypes/mattedWovenBag.svg';
import militaryStyleBag from '../images/bagTypes/militaryStyleBag.svg';
import miscellaneous from '../images/bagTypes/miscellaneous.svg';
import musicalInstrument from '../images/bagTypes/musicalInstrument.svg';
import noZipper from '../images/bagTypes/noZipper.svg';
import orthopaedicDevice from '../images/bagTypes/orthopaedicDevice.svg';
import other from '../images/bagTypes/other.svg';
import otherChildEquipment from '../images/bagTypes/otherChildEquipment.svg';
import otherSportEquipment from '../images/bagTypes/otherSportEquipment.svg';
import petCarrier from '../images/bagTypes/petCarrier.svg';
import plasticLaundryBag from '../images/bagTypes/plasticLaundryBag.svg';
import securityRemovedItem from '../images/bagTypes/securityRemovedItem.svg';
import shoppingBag from '../images/bagTypes/shoppingBag.svg';
import skiBoots from '../images/bagTypes/skiBoots.svg';
import skis from '../images/bagTypes/skis.svg';
import sleepingBag from '../images/bagTypes/sleepingBag.svg';
import snowboard from '../images/bagTypes/snowboard.svg';
import sportEquipment from '../images/bagTypes/sportEquipment.svg';
import storageContainer from '../images/bagTypes/storageContainer.svg';
import surfEquipment from '../images/bagTypes/surfEquipment.svg';
import toolBox from '../images/bagTypes/toolBox.svg';
import trunk from '../images/bagTypes/trunk.svg';
import tube from '../images/bagTypes/tube.svg';
import umbrella from '../images/bagTypes/umbrella.svg';
import umbrellaStroller from '../images/bagTypes/umbrellaStroller.svg';
import upright from '../images/bagTypes/upright.svg';
import uprightCombined from '../images/bagTypes/uprightCombined.svg';
import uprightExpandable from '../images/bagTypes/uprightExpandable.svg';
import uprightHard from '../images/bagTypes/uprightHard.svg';
import uprightSoft from '../images/bagTypes/uprightSoft.svg';
import wheelchair from '../images/bagTypes/wheelchair.svg';
import wheeledSportsItem from '../images/bagTypes/wheeledSportsItem.svg';
import zipper from '../images/bagTypes/zipper.svg';

export const steps = [
    {
        name: 'luggageMiscellaneous',
        options: [
            {
                text: 'luggage',
                src: uprightCombined,
                nextStep: 'zipperType',
            },
            {
                text: 'miscellaneous',
                src: miscellaneous,
                nextStep: 'miscellaneous',
            },
        ],
    },
    {
        name: 'zipperType',
        options: [
            { text: 'zipper', src: zipper, nextStep: 'zipperBags' },
            { text: 'noZipper', src: noZipper, nextStep: 'noZipperBags' },
        ],
    },
    {
        name: 'zipperBags',
        options: [
            { text: 'uprightSoft', src: uprightSoft, code: '22' },
            { text: 'uprightHard', src: uprightHard, code: '22R' },
            { text: 'uprightCombined', src: uprightCombined, code: '22D' },
            { text: 'horizontalSuitcase', src: horizontalSuitcase, code: '23' },
            { text: 'duffel', src: duffel, code: '25' },
            { text: 'backpack', src: backpack, code: '29' },
            { text: 'laptop', src: laptop, code: '26' },
            { text: 'garmentBag', src: garmentBag, code: '20' },
            { text: 'mattedWovenBag', src: mattedWovenBag, code: '28' },
            { text: 'uprightExpandable', src: uprightExpandable, code: '27' },
        ],
    },
    {
        name: 'noZipperBags',
        options: [
            { text: 'upright', src: upright, code: '02' },
            {
                text: 'horizontalHardshell',
                src: horizontalHardshell,
                code: '01',
            },
            {
                text: 'horizontalNonExpandable',
                src: horizontalNonExpandable,
                code: '03',
            },
            {
                text: 'horizontalExpandable',
                src: horizontalExpandable,
                code: '05',
            },
            { text: 'briefcase', src: briefcase, code: '06' },
            { text: 'documentCase', src: documentCase, code: '07' },
            { text: 'militaryStyleBag', src: militaryStyleBag, code: '08' },
            { text: 'plasticLaundryBag', src: plasticLaundryBag, code: '09' },
            { text: 'box', src: box, code: '10' },
            { text: 'storageContainer', src: storageContainer, code: '12' },
        ],
    },
    {
        name: 'miscellaneous',
        options: [
            {
                text: 'child',
                src: babyCarriage,
                nextStep: 'child',
            },
            {
                text: 'mobility',
                src: wheelchair,
                nextStep: 'mobility',
            },
            {
                text: 'sportEquipment',
                src: sportEquipment,
                nextStep: 'sportEquipment',
            },
            {
                text: 'storageContainer',
                src: storageContainer,
                nextStep: 'storageContainer',
            },
            { text: 'other', src: other, nextStep: 'other' },
        ],
    },
    {
        name: 'child',
        options: [
            { text: 'carSeat', src: carSeat, code: '71' },
            { text: 'babyCarriage', src: babyCarriage, code: '73' },
            { text: 'umbrellaStroller', src: umbrellaStroller, code: '74' },
            {
                text: 'otherChildEquipment',
                src: otherChildEquipment,
                code: '72',
            },
        ],
    },
    {
        name: 'mobility',
        options: [
            { text: 'baggageTrolley', src: baggageTrolley, code: '90' },
            { text: 'orthopaedicDevice', src: orthopaedicDevice, code: '95' },
            { text: 'wheelchair', src: wheelchair, code: '94' },
            { text: 'wheeledSportsItem', src: wheeledSportsItem, code: '75' },
        ],
    },
    {
        name: 'other',
        options: [
            {
                text: 'audioVideoEquipment',
                src: audioVideoEquipment,
                code: '81',
            },
            { text: 'baggageTrolley', src: baggageTrolley, code: '90' },
            { text: 'beddingBag', src: beddingBag, code: '96' },
            { text: 'computerEquipment', src: computerEquipment, code: '82' },
            {
                text: 'electricalAppliance',
                src: electricalAppliance,
                code: '83',
            },
            { text: 'foldingChair', src: foldingChair, code: '89' },
            {
                text: 'securityRemovedItem',
                src: securityRemovedItem,
                code: '92',
            },
            { text: 'musicalInstrument', src: musicalInstrument, code: '85' },
            { text: 'shoppingBag', src: shoppingBag, code: '93' },
            { text: 'umbrella', src: umbrella, code: '98' },
        ],
    },
    {
        name: 'storageContainer',
        options: [
            { text: 'artPortfolio', src: artPortfolio, code: '53' },
            { text: 'box', src: box, code: '10' },
            { text: 'cosmeticCase', src: cosmeticCase, code: '56' },
            { text: 'courierBag', src: courierBag, code: '51' },
            { text: 'dutyFreeItems', src: dutyFreeItems, code: '55' },
            { text: 'hatBox', src: hatBox, code: '50' },
            { text: 'cooler', src: cooler, code: '58' },
            { text: 'petCarrier', src: petCarrier, code: '57' },
            { text: 'toolBox', src: toolBox, code: '59' },
            { text: 'trunk', src: trunk, code: '52' },
            { text: 'tube', src: tube, code: '54' },
        ],
    },
    {
        name: 'sportEquipment',
        options: [
            { text: 'bicycle', src: bicycle, code: '63' },
            { text: 'diveBag', src: diveBag, code: '97' },
            { text: 'firearm', src: firearm, code: '61' },
            { text: 'fishingRod', src: fishingRod, code: '60' },
            { text: 'golfBag', src: golfBag, code: '62' },
            { text: 'skis', src: skis, code: '66' },
            { text: 'skiBoots', src: skiBoots, code: '68' },
            { text: 'sleepingBag', src: sleepingBag, code: '64' },
            { text: 'snowboard', src: snowboard, code: '67' },
            { text: 'surfEquipment', src: surfEquipment, code: '65' },
            { text: 'wheeledSportsItem', src: wheeledSportsItem, code: '75' },
            {
                text: 'otherSportEquipment',
                src: otherSportEquipment,
                code: '69',
            },
        ],
    },
];
