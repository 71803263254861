import declarations from '../constants/declarations';

export default function getLocationInfo({
    type,
    stationCode,
    flightNumber = '',
    flightCarrierCode = '',
}) {
    switch (type) {
        case declarations.BagReadyForLoadingDeclaration:
        case declarations.BagLoadedOnAircraftDeclaration: {
            if (!flightCarrierCode || !flightNumber) {
                return stationCode;
            }

            return `${stationCode}, Flight# ${flightCarrierCode} ${flightNumber}`;
        }
        case declarations.BagAcceptedDeclaration: {
            return '';
        }

        default: {
            return stationCode;
        }
    }
}
