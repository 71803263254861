import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Header from '../components/Header';
import HeaderInfo from '../components/HeaderInfo';
import Passenger from '../components/Passenger';
import Footer from '../components/Footer';
import EventsPerBag from '../containers/EventsPerBag';
import {
    findBagToRender,
    findPassengerWithBaggageTagNumber,
} from '../utils/findUtils';

export default class BagEvents extends Component {
    render() {
        const bagToRender = findBagToRender(
            this.props.passengers,
            this.props.location.match.params.baggageTagNumber
        );

        const passengerToRender = findPassengerWithBaggageTagNumber(
            this.props.passengers,
            this.props.location.match.params.baggageTagNumber
        );

        if (!bagToRender) {
            return (
                <Fragment>
                    <div className="body">
                        <h1>{this.props.labels.noBagFound}</h1>
                    </div>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <Header headerClass="header" />
                <div className="app">
                    <HeaderInfo passengerHeaderRoute={bagToRender.route} />
                    <div className="body">
                        <div className="bag-events">
                            <Passenger
                                {...passengerToRender}
                                labels={this.props.labels}
                            />
                            <EventsPerBag
                                bagToRender={bagToRender}
                                labels={this.props.labels}
                            />
                        </div>
                    </div>
                    <div className="bag-events__info-wrapper">
                        <div className="info">
                            <p>
                                {this.props.labels.contactUsStart}{' '}
                                {
                                    <a href={this.props.labels.contactUsLink}>
                                        {this.props.labels.contactUs}
                                    </a>
                                }{' '}
                                {this.props.labels.contactUsEnd}
                            </p>
                        </div>
                    </div>
                </div>
                <Footer
                    onRefresh={this.props.onRefresh}
                    isRefreshing={this.props.isRefreshing}
                    labels={this.props.labels}
                    lastUpdated={this.props.lastUpdated}
                    displayBackButton={true}
                    location={this.props.location}
                />
            </Fragment>
        );
    }
}

BagEvents.propTypes = {
    labels: PropTypes.instanceOf(Object).isRequired,
    onRefresh: PropTypes.func.isRequired,
    isRefreshing: PropTypes.bool.isRequired,
    lastUpdated: PropTypes.string.isRequired,
    passengers: PropTypes.instanceOf(Array),
    location: PropTypes.instanceOf(Object),
};
