import React, { useState } from 'react';
import {
    Button,
    ButtonSecondary,
    Checkbox,
    Fields,
    HorizontalFlex,
    PadContainer,
} from 'smartsuite-ui';
import FormWrapper from '../components/FormWrapper';
import { views } from '../constants/claimConstants';
import claimLabels from '../locales/en/claimLabels';

function ReviewForm({ location, setCurrentView }) {
    const [isChecked, setIsChecked] = useState(false);

    return (
        <FormWrapper instructions={claimLabels.instructionReviewForm}>
            <div className="review-form__fields">
                <Fields cssClassModifiers={['column', 'no-background']}>
                    <Checkbox
                        checked={isChecked}
                        onChange={(event) => setIsChecked(event.target.checked)}
                        checkboxLabel={claimLabels.reviewCheckbox}
                    />
                </Fields>
            </div>
            <PadContainer cssClassModifiers={['no-pad-left-right']}>
                <HorizontalFlex cssClassModifiers={['space-between']}>
                    <ButtonSecondary onClick={() => location.history.goBack()}>
                        {claimLabels.back}
                    </ButtonSecondary>
                    <Button
                        disabled={!isChecked}
                        onClick={() => setCurrentView(views.claimInformation)}
                    >
                        {claimLabels.next}
                    </Button>
                </HorizontalFlex>
            </PadContainer>
        </FormWrapper>
    );
}

export default ReviewForm;
