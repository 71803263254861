import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { AnimatedPageLoader } from 'brock-loader';
import {
    getPassengerIdentification,
    getPassengers,
} from './constants/apiConstants';
import { getLastUpdatedDate } from './utils/dateFormatUtils';
import { getRouteForPassengerHeader } from './utils/routeUtils';
import Header from './components/Header';
import Info from './components/Info';
import labels from './locales/en/labels';
import LandingPage from './pages/LandingPage';
import PassengerInfo from './pages/PassengerInfo';
import BagEvents from './pages/BagEvents';
import Claim from './pages/Claim';
import './fonts/SmartSuite-Icons.css';
import './fonts/aerLingusfonts.css';
import './styles/App.css';
import getQueryParam from './utils/getQueryParam';
import { transformPassengers } from './utils/transformPassenger';

function setCSSViewportHeightVariable() {
    const viewportHeight = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${viewportHeight}px`);
}

function getInitialState() {
    setCSSViewportHeightVariable();
    return {
        isLoaded: false,
        isRefreshing: false,
        passengers: null,
        lastUpdated: '',
        responseMessage: '',
        passengerHeaderRoute: [],
    };
}

export default class App extends Component {
    state = getInitialState();

    componentDidMount() {
        const { location } = this.props.location;
        if (
            location.pathname !== '/' &&
            location.search &&
            location.pathname !== '/claim'
        ) {
            this.fetchPassengerInformation(getQueryParam(this.props.location));
        }

        window.addEventListener('resize', () => setCSSViewportHeightVariable());

        window.addEventListener('orientationchange', () =>
            setCSSViewportHeightVariable()
        );
    }

    componentWillUnmount() {
        window.removeEventListener('resize');
        window.removeEventListener('orientationchange');
    }

    fetchPassengerIdentification = (state) => {
        this.setState({ isRefreshing: true });
        fetch(getPassengerIdentification(state), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((identification) =>
                this.fetchPassengerInformation(identification)
            );
    };

    fetchPassengerInformation = (identification) => {
        const noRecordsMessage =
            'No records found, please ensure correct name and booking reference are being used';
        fetch(getPassengers(), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: identification,
            }),
        })
            .then((response) => {
                if (response.status === 204) {
                    this.setState({
                        isRefreshing: false,
                        responseMessage: noRecordsMessage,
                    });
                    return Promise.reject();
                }
                return response.json();
            })
            .then((passengers) => {
                if (passengers.length === 0) {
                    this.setState({
                        isRefreshing: false,
                        responseMessage: noRecordsMessage,
                    });
                    this.props.location.history.push('/');
                    return;
                }

                this.setState({
                    isLoaded: true,
                    isRefreshing: false,
                    passengers: passengers,
                    passengerHeaderRoute:
                        getRouteForPassengerHeader(passengers),
                    lastUpdated: getLastUpdatedDate(),
                    responseMessage: '',
                });
                this.props.location.history.push(`/claim?${identification}`);
            })
            .catch((error) => console.log(error));
    };

    isHeaderRouteVisible = () => {
        return this.state.passengerHeaderRoute.length > 0;
    };

    onRefresh = () => {
        this.setState({ isRefreshing: true });
        fetch(getPassengers(), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: getQueryParam(this.props.location) }),
        })
            .then((response) => response.json())
            .then((passengers) => {
                this.setState({
                    isRefreshing: false,
                    passengers: passengers,
                    passengerHeaderRoute:
                        getRouteForPassengerHeader(passengers),
                    lastUpdated: getLastUpdatedDate(),
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isRefreshing: false,
                });
            });
    };

    resetInitialState = () => {
        this.setState(getInitialState());
    };

    render() {
        if (
            !this.state.isLoaded &&
            this.props.location.location.pathname !== '/' &&
            this.props.location.location.pathname !== '/claim'
        ) {
            return (
                <Fragment>
                    <Header />
                    <div className="body">
                        <Info info={labels.homeInfo} />
                        <div className="loader-container">
                            <AnimatedPageLoader />
                        </div>
                    </div>
                </Fragment>
            );
        }

        return (
            <div className="app-container">
                <Switch>
                    <Route
                        path="/passengerInfo/"
                        render={(location) => (
                            <PassengerInfo
                                labels={labels}
                                onRefresh={this.onRefresh}
                                isRefreshing={this.state.isRefreshing}
                                passengers={this.state.passengers}
                                lastUpdated={this.state.lastUpdated}
                                previousPath={'/'}
                                location={location}
                                passengerHeaderRoute={
                                    this.state.passengerHeaderRoute
                                }
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/BagEvents/:baggageTagNumber/"
                        render={(location) => (
                            <BagEvents
                                labels={labels}
                                onRefresh={this.onRefresh}
                                isRefreshing={this.state.isRefreshing}
                                passengers={this.state.passengers}
                                lastUpdated={this.state.lastUpdated}
                                location={location}
                            />
                        )}
                    />
                    <Route
                        path="/claim/"
                        render={(location) => (
                            <Claim
                                location={location}
                                passengerData={transformPassengers(
                                    this.state.passengers
                                )}
                            />
                        )}
                    />
                    <Route
                        render={() => (
                            <LandingPage
                                labels={labels}
                                isRefreshing={this.state.isRefreshing}
                                responseMessage={this.state.responseMessage}
                                resetInitialState={this.resetInitialState}
                                fetchPassengerIdentification={
                                    this.fetchPassengerIdentification
                                }
                            />
                        )}
                    />
                </Switch>
            </div>
        );
    }
}

App.propTypes = {
    location: PropTypes.instanceOf(Object).isRequired,
};
