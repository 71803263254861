import React, { useState } from 'react';
import { Field, HorizontalFlex, icons } from 'smartsuite-ui';
import { micrositeIcons } from '../constants/claimConstants';
import claimLabels from '../locales/en/claimLabels';
import BagTypeModal from './BagTypeModal';

function renderType(type) {
    return (
        <HorizontalFlex>
            <img
                alt={type.text}
                className="bag-type-input__icon"
                src={type.src}
            />
            <span>{claimLabels[type.text]}</span>
        </HorizontalFlex>
    );
}

function renderTypePlaceholder() {
    return (
        <HorizontalFlex>
            <span className="bag-type-input__placeholder">
                <HorizontalFlex>
                    <div className="bag-type-input__icon">
                        <span className={micrositeIcons.bagQuestion} />
                    </div>
                    {claimLabels.selectType}
                </HorizontalFlex>
            </span>
        </HorizontalFlex>
    );
}

function BagTypeInput({ selectedType, tagNumber, updateType }) {
    const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);

    return (
        <Field label={claimLabels.type}>
            <div
                className="bag-type-input"
                onClick={() => setIsTypeModalOpen(true)}
            >
                {selectedType
                    ? renderType(selectedType)
                    : renderTypePlaceholder()}
                <span
                    className={`${icons.chevronDown} bag-type-input__action-icon`}
                />
            </div>
            {isTypeModalOpen && (
                <BagTypeModal
                    closeModal={() => setIsTypeModalOpen(false)}
                    tagNumber={tagNumber}
                    updateType={updateType}
                />
            )}
        </Field>
    );
}

export default BagTypeInput;
