const ALPHANUMERIC_REGEX = new RegExp('^[a-z0-9 ]+$', 'i');
const ALPHANUMERIC_ACCENT_REGEX = new RegExp(
    "^[a-zàâçéèêëîïôûùüÿñæœ0-9 .'-]+$",
    'i'
);
const ALPHA_REGEX = new RegExp("^[a-zàâçéèêëîïôûùüÿñæœ .'-]+$", 'i');
// prettier-ignore
const PHONE_REGEX = new RegExp('^[\+]?[a-z0-9 ]*$', 'i'); // eslint-disable-line
const INVALID_CHARACTERS_REGEX = /["<>'`%;&+()]/i;

export const PHONE_MIN_LENGTH = 8;
export const EMAIL_MIN_LENGTH = 6;
export const STATE_MIN_LENGTH = 2;

export function isPhoneLongEnough(value) {
    if (!value) {
        return true;
    }

    return value.length >= PHONE_MIN_LENGTH;
}

export function isEmailLongEnough(value) {
    if (!value) {
        return true;
    }

    return value.length >= EMAIL_MIN_LENGTH;
}

export function isStateLongEnough(value) {
    if (!value) {
        return true;
    }

    return value.length >= STATE_MIN_LENGTH;
}

export function isAlphanumeric(value) {
    if (!value) {
        return true;
    }

    return ALPHANUMERIC_REGEX.test(value);
}

export function isAlphanumericAccent(value) {
    if (!value) {
        return true;
    }

    return ALPHANUMERIC_ACCENT_REGEX.test(value);
}

export function isAlpha(value) {
    if (!value) {
        return true;
    }

    return ALPHA_REGEX.test(value);
}

export function isPhoneValidCharacters(value) {
    if (!value) {
        return true;
    }

    return PHONE_REGEX.test(value);
}

export function getPhoneErrorMessage(claimLabels, field, value) {
    if (!value) {
        return '';
    }

    if (!isPhoneValidCharacters(value)) {
        return `${claimLabels[field]} ${claimLabels.inputInvalid}`;
    } else if (!isPhoneLongEnough(value)) {
        return `${claimLabels.inputTooShort1} ${PHONE_MIN_LENGTH} ${claimLabels.inputTooShort2}`;
    }
}

export function isInputContainingInvalidCharacters(value) {
    return INVALID_CHARACTERS_REGEX.test(value);
}

export function getEmailErrorMessage(claimLabels, value) {
    if (!value) {
        return '';
    }

    if (!isEmailLongEnough(value)) {
        return `${claimLabels.inputTooShort1} ${EMAIL_MIN_LENGTH} ${claimLabels.inputTooShort2}`;
    }
}

export function getStateErrorMessage(claimLabels, value) {
    if (!value) {
        return '';
    }

    if (!isAlpha(value)) {
        return `${claimLabels.state} ${claimLabels.inputInvalid}`;
    } else if (!isStateLongEnough(value)) {
        return `${claimLabels.inputTooShort1} ${STATE_MIN_LENGTH} ${claimLabels.inputTooShort2}`;
    }
}
