import React, { useState } from 'react';
import {
    Button,
    ButtonSecondary,
    Fields,
    PadContainer,
    Title,
} from 'smartsuite-ui';

import BagColourInput from '../components/BagColourInput';
import BagTypeInput from '../components/BagTypeInput';
import FormFooter from '../components/FormFooter';
import FormWrapper from '../components/FormWrapper';
import { views } from '../constants/claimConstants';
import { useClaimContext } from '../contexts/ClaimContext';
import claimLabels from '../locales/en/claimLabels';

function BagInformationForm({ setCurrentView }) {
    const { claim, updateClaim } = useClaimContext();
    const [claimBags, setClaimBags] = useState(claim.bags);

    function updateColour(tagNumber, colour) {
        setClaimBags((prevState) => {
            const bagInClaim = prevState.find(
                (bag) => bag.tagNumber === tagNumber
            );
            if (bagInClaim) {
                return prevState.map((bag) =>
                    bag.tagNumber === tagNumber ? { ...bag, colour } : bag
                );
            } else {
                return [...prevState, { tagNumber, colour }];
            }
        });
    }

    function updateType(tagNumber, type) {
        setClaimBags((prevState) => {
            const bagInClaim = prevState.find(
                (bag) => bag.tagNumber === tagNumber
            );
            if (bagInClaim) {
                return prevState.map((bag) =>
                    bag.tagNumber === tagNumber ? { ...bag, type } : bag
                );
            } else {
                return [...prevState, { tagNumber, type }];
            }
        });
    }

    function onNavigate(view) {
        updateClaim({ bags: claimBags });
        setCurrentView(view);
    }

    function isFormValid() {
        return claimBags.every((bag) => !!bag.colour && !!bag.type);
    }

    return (
        <FormWrapper
            instructions={claimLabels.instructionBagInformationForm}
            title={claimLabels.titleBagInformationForm}
        >
            {claimBags.map((claimBag, index) => {
                const selectedColour = claimBag ? claimBag.colour : '';
                const selectedType = claimBag ? claimBag.type : '';

                return (
                    <div key={claimBag.tagNumber} className="form-fields">
                        <Fields cssClassModifiers={['column', 'no-background']}>
                            <PadContainer
                                cssClassModifiers={[
                                    'pad-small',
                                    'no-pad-top-bottom',
                                ]}
                            >
                                <Title cssClassModifiers={['size-small']}>
                                    {`${claimLabels.item} ${index + 1}`}
                                </Title>
                            </PadContainer>
                            <BagColourInput
                                selectedColour={selectedColour}
                                tagNumber={claimBag.tagNumber}
                                updateColour={updateColour}
                            />
                            <BagTypeInput
                                selectedType={selectedType}
                                tagNumber={claimBag.tagNumber}
                                updateType={updateType}
                            />
                        </Fields>
                    </div>
                );
            })}
            <FormFooter
                navigateToPassengerInformation={() =>
                    setCurrentView(views.claimInformation)
                }
            >
                <ButtonSecondary
                    onClick={() => onNavigate(views.claimInformation)}
                >
                    {claimLabels.back}
                </ButtonSecondary>
                <PadContainer cssClassModifiers={['pad-small']} />
                <Button
                    disabled={!isFormValid()}
                    onClick={() => onNavigate(views.address)}
                >
                    {claimLabels.next}
                </Button>
            </FormFooter>
        </FormWrapper>
    );
}

export default BagInformationForm;
