export function findPassengerWithBaggageTagNumber(
    passengers,
    baggageTagNumber
) {
    return passengers.find((passenger) =>
        passenger.bags.find((bag) => bag.baggageTagNumber === baggageTagNumber)
    );
}

function findPassengersBagWithBaggageTagNumber(passenger, baggageTagNumber) {
    return passenger.bags.find(
        (bag) => bag.baggageTagNumber === baggageTagNumber
    );
}

export function findBagToRender(passengers, baggageTagNumber) {
    const passenger = findPassengerWithBaggageTagNumber(
        passengers,
        baggageTagNumber
    );

    return !passenger
        ? passenger
        : findPassengersBagWithBaggageTagNumber(passenger, baggageTagNumber);
}
