import { DateTimeFormatter } from 'brock-date-time';

export default function createTracerRecordRequest(claim, customsInformation) {
    const {
        permanentAddress,
        temporaryAddress,
        isTemporaryAddress,
        bags: claimBags,
        passengers: claimPassengers,
    } = claim;

    let contactInformation = {
        cellPhoneNumber: isTemporaryAddress
            ? temporaryAddress.cellNumber
            : permanentAddress.cellNumber,
        email: isTemporaryAddress
            ? temporaryAddress.email
            : permanentAddress.email,
        permanentAddress: {
            ...permanentAddress,
            addressLines: [
                {
                    sequence: 0,
                    address: permanentAddress.lineOne,
                },
                {
                    sequence: 1,
                    address: permanentAddress.lineTwo,
                },
            ],
            country: permanentAddress.country,
        },
        permanentPhoneNumber: permanentAddress.phoneNumber,
    };

    if (isTemporaryAddress) {
        contactInformation = {
            ...contactInformation,
            temporaryAddress: {
                ...temporaryAddress,
                addressLines: [
                    {
                        sequence: 0,
                        address: temporaryAddress.lineOne,
                    },
                    {
                        sequence: 1,
                        address: temporaryAddress.lineTwo,
                    },
                ],
                country: temporaryAddress.country,
            },
            temporaryPhoneNumber: temporaryAddress.phoneNumber,
            tempValidUntilDate: DateTimeFormatter.date(
                temporaryAddress.validityDate
            ),
        };
    }

    const delayedBagGroup = claimBags.map((bag) => {
        return {
            ...bag,
            colorTypeDescription: {
                colorCode: bag.colour.code,
                typeCode: bag.type.code,
            },
        };
    });

    const passengers = claimPassengers.map((passenger) => ({
        ...passenger,
        contactInformation,
        frequentFlyerNumber: isTemporaryAddress
            ? temporaryAddress.frequentFlyerNumber
            : permanentAddress.frequentFlyerNumber,
    }));

    const claimRequest = {
        delayedBagGroup,
        passengers,
        customsInformation,
    };

    return claimRequest;
}
