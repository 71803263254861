import moment from 'moment';

export function getLastUpdatedDate() {
    return moment().format('DD MMM, HH:mm');
}

export function getDateFormattedForEvents(date = new Date()) {
    return `${moment(date).format('ddd, DD MMM - HH:mm')} GMT`;
}

export function getDateForLegs(date) {
    return moment(date).format('DD MMM');
}
