import moment from 'moment';
import { getDateForLegs } from './dateFormatUtils';

export function transformPassengers(passengers) {
    if (!passengers) {
        return null;
    }

    return passengers.map((passenger) => {
        const { passengerName, passengerPNR, customsFormDisplayPage, bags } =
            passenger;
        const index = passengerName.indexOf('/');

        const bagsWithRoute = bags.filter(
            (bag) => bag.route && bag.route.length !== 0
        );

        const passengerBags = bagsWithRoute.map((bag) => {
            const { baggageTagNumber, route, events } = bag;

            const date = route[0].flightDepartureDateLocal;

            return {
                tagNumber: baggageTagNumber,
                itinerary: route
                    ? route.map((leg) => ({
                          airlineCode: leg.flightCarrierCode,
                          flightNumber: leg.flightNumber,
                          departureDate: leg.flightDepartureDateLocal,
                          arrivalStation: leg.flightArrivalStationCode,
                          departureStation: leg.flightDepartureStationCode,
                      }))
                    : [],
                stationCode:
                    events && events.length > 0 ? events[0].stationCode : '',
                flightDate: moment(date),
                flightDateFormatted: getDateForLegs(date),
                passengerName,
            };
        });

        return {
            passenger: {
                firstInitial: passengerName.substr(index + 1, 1),
                lastname: passengerName.substr(0, index),
                pnr: passengerPNR,
                passengerName,
                customsFormDisplayPage,
                itinerary: passenger.route
                    ? passenger.route.map((leg) => ({
                          airlineCode: leg.flightCarrierCode,
                          flightNumber: leg.flightNumber,
                          departureDate: leg.flightDepartureDateLocal,
                          arrivalStation: leg.flightArrivalStationCode,
                          departureStation: leg.flightDepartureStationCode,
                      }))
                    : [],
            },
            bags: passengerBags,
        };
    });
}
