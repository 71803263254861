import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStylesInput = makeStyles({
    root: {
        border: '1px solid #cccccc',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: '#fff',
        color: '#2C2A29',

        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            backgroundColor: '#fff',
            borderColor: '#006272',
        },
    },
    focused: {},
});

const useStylesLabel = makeStyles({
    root: {
        color: '#2C2A29',

        '&$focused': {
            color: '#006272',
        },
    },
    shrink: {},
    focused: {},
});

function TextInput(props) {
    const inputClasses = useStylesInput();
    const labelClasses = useStylesLabel();

    return (
        <TextField
            variant="filled"
            InputProps={{ classes: inputClasses, disableUnderline: true }}
            InputLabelProps={{ classes: labelClasses }}
            {...props}
        />
    );
}

export default TextInput;
