import React from 'react';
import { ButtonSecondary, PadContainer } from 'smartsuite-ui';
import claimLabels from '../locales/en/claimLabels';
import BagTicket from './BagTicket';
import ContactInformation from './ContactInformation';
import FormWrapper from './FormWrapper';

function ErrorPage({ goBack }) {
    return (
        <FormWrapper instructions={claimLabels.instructionGeneralError}>
            <BagTicket />
            <ContactInformation />
            <PadContainer cssClassModifiers={['no-pad-left-right']}>
                <ButtonSecondary onClick={goBack}>
                    {claimLabels.back}
                </ButtonSecondary>
            </PadContainer>
        </FormWrapper>
    );
}

export default ErrorPage;
