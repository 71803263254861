import React, { useState } from 'react';
import { Field, HorizontalFlex, icons } from 'smartsuite-ui';
import { micrositeIcons } from '../constants/claimConstants';
import claimLabels from '../locales/en/claimLabels';
import BagColourModal from './BagColourModal';

function renderColour(colour) {
    return (
        <HorizontalFlex>
            <img
                alt={colour.text}
                className="bag-colour-input__icon"
                src={colour.src}
            />
            <span>{claimLabels[colour.text]}</span>
        </HorizontalFlex>
    );
}

function renderColourPlaceholder() {
    return (
        <HorizontalFlex>
            <span className="bag-colour-input__placeholder">
                <HorizontalFlex>
                    <div className="bag-colour-input__icon">
                        <span className={micrositeIcons.noColour} />
                    </div>
                    {claimLabels.selectColour}
                </HorizontalFlex>
            </span>
        </HorizontalFlex>
    );
}

function BagColourInput({ selectedColour, tagNumber, updateColour }) {
    const [isColourModalOpen, setIsColourModalOpen] = useState(false);

    return (
        <Field label={claimLabels.colour}>
            <div
                className="bag-colour-input"
                onClick={() => setIsColourModalOpen(true)}
            >
                {selectedColour
                    ? renderColour(selectedColour)
                    : renderColourPlaceholder()}
                <span
                    className={`${icons.chevronDown} bag-colour-input__action-icon`}
                />
            </div>
            {isColourModalOpen && (
                <BagColourModal
                    closeModal={() => setIsColourModalOpen(false)}
                    tagNumber={tagNumber}
                    updateColour={updateColour}
                />
            )}
        </Field>
    );
}

export default BagColourInput;
