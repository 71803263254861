import React, { createContext, useContext, useReducer, useState } from 'react';
import { postDataPromise } from 'brock-fetch-utils';
import { submitClaimUri } from '../constants/apiConstants';
import { claimReducerActions } from '../constants/claimConstants';
import { claimReducer, initialState } from '../reducers/claimReducer';
import createTracerRecordRequest from '../utils/createTracerRecordRequest';

const ClaimContext = createContext();

export function ClaimContextProvider({ children }) {
    const [claim, dispatch] = useReducer(claimReducer, initialState);
    const [submitClaimResponse, setSubmitClaimResponse] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    function updateClaim(payload) {
        dispatch({ type: claimReducerActions.UPDATE, payload });
    }

    function emptyClaim() {
        dispatch({ type: claimReducerActions.EMPTY });
    }

    function submitClaim(fullClaim, customsInformation = {}) {
        setIsFetching(true);
        const request = createTracerRecordRequest(
            fullClaim,
            customsInformation
        );

        postDataPromise(submitClaimUri(), request)
            .then(
                (response) => {
                    const passengerNames = request.passengers.map(
                        (passenger) => passenger.passengerName
                    );
                    emptyClaim();
                    setSubmitClaimResponse({ ...response, passengerNames });
                },
                (error) => {
                    setSubmitClaimResponse({ isSuccess: false });
                }
            )
            .then(() => {
                setIsFetching(false);
            });
    }

    return (
        <ClaimContext.Provider
            value={{
                claim,
                emptyClaim,
                isFetching,
                submitClaim,
                submitClaimResponse,
                updateClaim,
            }}
        >
            {children}
        </ClaimContext.Provider>
    );
}

export function useClaimContext() {
    const context = useContext(ClaimContext);

    if (!context) {
        throw new Error(
            'useClaimContext must be used within a ClaimContextProvider. Wrap a parent component in <ClaimContextProvider to fix this error'
        );
    }

    return context;
}
