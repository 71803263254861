import React from 'react';
import { PadContainer, VerticalFlex } from 'smartsuite-ui';
import claimLabels from '../locales/en/claimLabels';

/* eslint-disable react/jsx-no-target-blank */
function ContactInformation() {
    return (
        <VerticalFlex>
            <PadContainer cssClassModifiers={['no-pad-left']}>
                <div className="contact-information__title">
                    {claimLabels.contactInformation1}
                </div>
                <span>{claimLabels.contactInformation2}</span>
                <a
                    href={
                        'https://www.aerlingus.com/travel-information/baggage-information/missing-baggage/#/tab-0-delayed-or-missing-baggage'
                    }
                    target="_blank"
                >
                    {claimLabels.contactInformation3}
                </a>
                .
            </PadContainer>
            <PadContainer cssClassModifiers={['no-pad-left', 'no-pad-top']}>
                <div className="contact-information__title">
                    {claimLabels.contactInformation4}
                </div>
                <span>{claimLabels.contactInformation5}</span>
            </PadContainer>
            <PadContainer cssClassModifiers={['no-pad-left', 'no-pad-top']}>
                <div className="contact-information__title">
                    {claimLabels.contactInformation6}
                </div>
                <span>{claimLabels.contactInformation7}</span>
                <a
                    href={
                        'https://www.aerlingus.com/travel-information/baggage-information/missing-baggage/#/tab-0-delayed-or-missing-baggage'
                    }
                    target="_blank"
                >
                    {claimLabels.contactInformation8}
                </a>
                .
            </PadContainer>
            <PadContainer cssClassModifiers={['no-pad-left', 'no-pad-top']}>
                <div className="contact-information__title">
                    {claimLabels.contactInformation9}
                </div>
                <a
                    href={'https://www.aerlingus.com/support/contact-us/'}
                    target="_blank"
                >
                    {claimLabels.contactInformation10}
                </a>
                <span>{claimLabels.contactInformation11}</span>
            </PadContainer>
            <PadContainer cssClassModifiers={['no-pad-left', 'no-pad-top']}>
                <div className="contact-information__title">
                    {claimLabels.contactInformation12}
                </div>
                <span>{claimLabels.contactInformation13}</span>
                <a
                    href="http://www.aerlingus.com/media/docs/BaggageTracingQuestionaire.docx"
                    target="_blank"
                >
                    {claimLabels.contactInformation14}
                </a>
                <span>{claimLabels.contactInformation15}</span>
            </PadContainer>
        </VerticalFlex>
    );
}

export default ContactInformation;
