export const countries = [
    { textField: 'Afghanistan', valueField: 'AF' },
    { textField: 'Åland Islands', valueField: 'AX' },
    { textField: 'Albania', valueField: 'AL' },
    { textField: 'Algeria', valueField: 'DZ' },
    { textField: 'American Samoa', valueField: 'AS' },
    { textField: 'Andorra', valueField: 'AD' },
    { textField: 'Angola', valueField: 'AO' },
    { textField: 'Anguilla', valueField: 'AI' },
    { textField: 'Antarctica', valueField: 'AQ' },
    { textField: 'Antigua and Barbuda', valueField: 'AG' },
    { textField: 'Argentina', valueField: 'AR' },
    { textField: 'Armenia', valueField: 'AM' },
    { textField: 'Aruba', valueField: 'AW' },
    { textField: 'Australia', valueField: 'AU' },
    { textField: 'Austria', valueField: 'AT' },
    { textField: 'Azerbaijan', valueField: 'AZ' },
    { textField: 'Bahamas', valueField: 'BS' },
    { textField: 'Bahrain', valueField: 'BH' },
    { textField: 'Bangladesh', valueField: 'BD' },
    { textField: 'Barbados', valueField: 'BB' },
    { textField: 'Belarus', valueField: 'BY' },
    { textField: 'Belgium', valueField: 'BE' },
    { textField: 'Belize', valueField: 'BZ' },
    { textField: 'Benin', valueField: 'BJ' },
    { textField: 'Bermuda', valueField: 'BM' },
    { textField: 'Bhutan', valueField: 'BT' },
    { textField: 'Bolivia', valueField: 'BO' },
    { textField: 'Bonaire, Sint Eustatius and Saba', valueField: 'BQ' },
    { textField: 'Bosnia and Herzegovina', valueField: 'BA' },
    { textField: 'Botswana', valueField: 'BW' },
    { textField: 'Bouvet Island', valueField: 'BV' },
    { textField: 'Brazil', valueField: 'BR' },
    { textField: 'British Indian Ocean Territory', valueField: 'IO' },
    { textField: 'Brunei Darussalam', valueField: 'BN' },
    { textField: 'Bulgaria', valueField: 'BG' },
    { textField: 'Burkina Faso', valueField: 'BF' },
    { textField: 'Burundi', valueField: 'BI' },
    { textField: 'Cabo Verde', valueField: 'CV' },
    { textField: 'Cambodia', valueField: 'KH' },
    { textField: 'Cameroon', valueField: 'CM' },
    { textField: 'Canada', valueField: 'CA' },
    { textField: 'Cayman Islands', valueField: 'KY' },
    { textField: 'Central African Republic', valueField: 'CF' },
    { textField: 'Chad', valueField: 'TD' },
    { textField: 'Chile', valueField: 'CL' },
    { textField: 'China', valueField: 'CN' },
    { textField: 'Christmas Island', valueField: 'CX' },
    { textField: 'Cocos (Keeling) Islands', valueField: 'CC' },
    { textField: 'Colombia', valueField: 'CO' },
    { textField: 'Comoros', valueField: 'KM' },
    { textField: 'Congo', valueField: 'CG' },
    { textField: 'Congo, Democratic Republic of the', valueField: 'CD' },
    { textField: 'Cook Islands', valueField: 'CK' },
    { textField: 'Costa Rica', valueField: 'CR' },
    { textField: "Côte d'Ivoire", valueField: 'CI' },
    { textField: 'Croatia', valueField: 'HR' },
    { textField: 'Cuba', valueField: 'CU' },
    { textField: 'Curaçao', valueField: 'CW' },
    { textField: 'Cyprus', valueField: 'CY' },
    { textField: 'Czechia', valueField: 'CZ' },
    { textField: 'Denmark', valueField: 'DK' },
    { textField: 'Djibouti', valueField: 'DJ' },
    { textField: 'Dominica', valueField: 'DM' },
    { textField: 'Dominican Republic', valueField: 'DO' },
    { textField: 'Ecuador', valueField: 'EC' },
    { textField: 'Egypt', valueField: 'EG' },
    { textField: 'El Salvador', valueField: 'SV' },
    { textField: 'Equatorial Guinea', valueField: 'GQ' },
    { textField: 'Eritrea', valueField: 'ER' },
    { textField: 'Estonia', valueField: 'EE' },
    { textField: 'Eswatini', valueField: 'SZ' },
    { textField: 'Ethiopia', valueField: 'ET' },
    { textField: 'Falkland Islands', valueField: 'FK' },
    { textField: 'Faroe Islands', valueField: 'FO' },
    { textField: 'Fiji', valueField: 'FJ' },
    { textField: 'Finland', valueField: 'FI' },
    { textField: 'France', valueField: 'FR' },
    { textField: 'French Guiana', valueField: 'GF' },
    { textField: 'French Polynesia', valueField: 'PF' },
    { textField: 'French Southern Territories', valueField: 'TF' },
    { textField: 'Gabon', valueField: 'GA' },
    { textField: 'Gambia', valueField: 'GM' },
    { textField: 'Georgia', valueField: 'GE' },
    { textField: 'Germany', valueField: 'DE' },
    { textField: 'Ghana', valueField: 'GH' },
    { textField: 'Gibraltar', valueField: 'GI' },
    { textField: 'Greece', valueField: 'GR' },
    { textField: 'Greenland', valueField: 'GL' },
    { textField: 'Grenada', valueField: 'GD' },
    { textField: 'Guadeloupe', valueField: 'GP' },
    { textField: 'Guam', valueField: 'GU' },
    { textField: 'Guatemala', valueField: 'GT' },
    { textField: 'Guernsey', valueField: 'GG' },
    { textField: 'Guinea', valueField: 'GN' },
    { textField: 'Guinea-Bissau', valueField: 'GW' },
    { textField: 'Guyana', valueField: 'GY' },
    { textField: 'Haiti', valueField: 'HT' },
    { textField: 'Heard Island and McDonald Islands', valueField: 'HM' },
    { textField: 'Holy See', valueField: 'VA' },
    { textField: 'Honduras', valueField: 'HN' },
    { textField: 'Hong Kong', valueField: 'HK' },
    { textField: 'Hungary', valueField: 'HU' },
    { textField: 'Iceland', valueField: 'IS' },
    { textField: 'India', valueField: 'IN' },
    { textField: 'Indonesia', valueField: 'ID' },
    { textField: 'Iran', valueField: 'IR' },
    { textField: 'Iraq', valueField: 'IQ' },
    { textField: 'Ireland', valueField: 'IE' },
    { textField: 'Isle of Man', valueField: 'IM' },
    { textField: 'Israel', valueField: 'IL' },
    { textField: 'Italy', valueField: 'IT' },
    { textField: 'Jamaica', valueField: 'JM' },
    { textField: 'Japan', valueField: 'JP' },
    { textField: 'Jersey', valueField: 'JE' },
    { textField: 'Jordan', valueField: 'JO' },
    { textField: 'Kazakhstan', valueField: 'KZ' },
    { textField: 'Kenya', valueField: 'KE' },
    { textField: 'Kiribati', valueField: 'KI' },
    { textField: "Korea, Democratic People's Republic of", valueField: 'KP' },
    { textField: 'Korea, Republic of', valueField: 'KR' },
    { textField: 'Kuwait', valueField: 'KW' },
    { textField: 'Kyrgyzstan', valueField: 'KG' },
    { textField: "Lao People's Democratic Republic", valueField: 'LA' },
    { textField: 'Latvia', valueField: 'LV' },
    { textField: 'Lebanon', valueField: 'LB' },
    { textField: 'Lesotho', valueField: 'LS' },
    { textField: 'Liberia', valueField: 'LR' },
    { textField: 'Libya', valueField: 'LY' },
    { textField: 'Liechtenstein', valueField: 'LI' },
    { textField: 'Lithuania', valueField: 'LT' },
    { textField: 'Luxembourg', valueField: 'LU' },
    { textField: 'Macao', valueField: 'MO' },
    { textField: 'North Macedonia', valueField: 'MK' },
    { textField: 'Madagascar', valueField: 'MG' },
    { textField: 'Malawi', valueField: 'MW' },
    { textField: 'Malaysia', valueField: 'MY' },
    { textField: 'Maldives', valueField: 'MV' },
    { textField: 'Mali', valueField: 'ML' },
    { textField: 'Malta', valueField: 'MT' },
    { textField: 'Marshall Islands', valueField: 'MH' },
    { textField: 'Martinique', valueField: 'MQ' },
    { textField: 'Mauritania', valueField: 'MR' },
    { textField: 'Mauritius', valueField: 'MU' },
    { textField: 'Mayotte', valueField: 'YT' },
    { textField: 'Mexico', valueField: 'MX' },
    { textField: 'Micronesia', valueField: 'FM' },
    { textField: 'Moldova', valueField: 'MD' },
    { textField: 'Monaco', valueField: 'MC' },
    { textField: 'Mongolia', valueField: 'MN' },
    { textField: 'Montenegro', valueField: 'ME' },
    { textField: 'Montserrat', valueField: 'MS' },
    { textField: 'Morocco', valueField: 'MA' },
    { textField: 'Mozambique', valueField: 'MZ' },
    { textField: 'Myanmar', valueField: 'MM' },
    { textField: 'Namibia', valueField: 'NA' },
    { textField: 'Nauru', valueField: 'NR' },
    { textField: 'Nepal', valueField: 'NP' },
    { textField: 'Netherlands', valueField: 'NL' },
    { textField: 'New Caledonia', valueField: 'NC' },
    { textField: 'New Zealand', valueField: 'NZ' },
    { textField: 'Nicaragua', valueField: 'NI' },
    { textField: 'Niger', valueField: 'NE' },
    { textField: 'Nigeria', valueField: 'NG' },
    { textField: 'Niue', valueField: 'NU' },
    { textField: 'Norfolk Island', valueField: 'NF' },
    { textField: 'Northern Mariana Islands', valueField: 'MP' },
    { textField: 'Norway', valueField: 'NO' },
    { textField: 'Oman', valueField: 'OM' },
    { textField: 'Pakistan', valueField: 'PK' },
    { textField: 'Palau', valueField: 'PW' },
    { textField: 'Palestine, State of', valueField: 'PS' },
    { textField: 'Panama', valueField: 'PA' },
    { textField: 'Papua New Guinea', valueField: 'PG' },
    { textField: 'Paraguay', valueField: 'PY' },
    { textField: 'Peru', valueField: 'PE' },
    { textField: 'Philippines', valueField: 'PH' },
    { textField: 'Pitcairn', valueField: 'PN' },
    { textField: 'Poland', valueField: 'PL' },
    { textField: 'Portugal', valueField: 'PT' },
    { textField: 'Puerto Rico', valueField: 'PR' },
    { textField: 'Qatar', valueField: 'QA' },
    { textField: 'Réunion', valueField: 'RE' },
    { textField: 'Romania', valueField: 'RO' },
    { textField: 'Russian Federation', valueField: 'RU' },
    { textField: 'Rwanda', valueField: 'RW' },
    { textField: 'Saint Barthélemy', valueField: 'BL' },
    {
        textField: 'Saint Helena, Ascension and Tristan da Cunha',
        valueField: 'SH',
    },
    { textField: 'Saint Kitts and Nevis', valueField: 'KN' },
    { textField: 'Saint Lucia', valueField: 'LC' },
    { textField: 'Saint Martin (French part)', valueField: 'MF' },
    { textField: 'Saint Pierre and Miquelon', valueField: 'PM' },
    { textField: 'Saint Vincent and the Grenadines', valueField: 'VC' },
    { textField: 'Samoa', valueField: 'WS' },
    { textField: 'San Marino', valueField: 'SM' },
    { textField: 'Sao Tome and Principe', valueField: 'ST' },
    { textField: 'Saudi Arabia', valueField: 'SA' },
    { textField: 'Senegal', valueField: 'SN' },
    { textField: 'Serbia', valueField: 'RS' },
    { textField: 'Seychelles', valueField: 'SC' },
    { textField: 'Sierra Leone', valueField: 'SL' },
    { textField: 'Singapore', valueField: 'SG' },
    { textField: 'Sint Maarten (Dutch part)', valueField: 'SX' },
    { textField: 'Slovakia', valueField: 'SK' },
    { textField: 'Slovenia', valueField: 'SI' },
    { textField: 'Solomon Islands', valueField: 'SB' },
    { textField: 'Somalia', valueField: 'SO' },
    { textField: 'South Africa', valueField: 'ZA' },
    {
        textField: 'South Georgia and the South Sandwich Islands',
        valueField: 'GS',
    },
    { textField: 'South Sudan', valueField: 'SS' },
    { textField: 'Spain', valueField: 'ES' },
    { textField: 'Sri Lanka', valueField: 'LK' },
    { textField: 'Sudan', valueField: 'SD' },
    { textField: 'Suriname', valueField: 'SR' },
    { textField: 'Svalbard and Jan Mayen', valueField: 'SJ' },
    { textField: 'Sweden', valueField: 'SE' },
    { textField: 'Switzerland', valueField: 'CH' },
    { textField: 'Syrian Arab Republic', valueField: 'SY' },
    { textField: 'Taiwan', valueField: 'TW' },
    { textField: 'Tajikistan', valueField: 'TJ' },
    { textField: 'Tanzania, the United Republic of', valueField: 'TZ' },
    { textField: 'Thailand', valueField: 'TH' },
    { textField: 'Timor-Leste', valueField: 'TL' },
    { textField: 'Togo', valueField: 'TG' },
    { textField: 'Tokelau', valueField: 'TK' },
    { textField: 'Tonga', valueField: 'TO' },
    { textField: 'Trinidad and Tobago', valueField: 'TT' },
    { textField: 'Tunisia', valueField: 'TN' },
    { textField: 'Turkey', valueField: 'TR' },
    { textField: 'Turkmenistan', valueField: 'TM' },
    { textField: 'Turks and Caicos Islands', valueField: 'TC' },
    { textField: 'Tuvalu', valueField: 'TV' },
    { textField: 'Uganda', valueField: 'UG' },
    { textField: 'Ukraine', valueField: 'UA' },
    { textField: 'United Arab Emirates', valueField: 'AE' },
    {
        textField: 'United Kingdom of Great Britain and Northern Ireland',
        valueField: 'GB',
    },
    { textField: 'United States Minor Outlying Islands', valueField: 'UM' },
    { textField: 'United States of America', valueField: 'US' },
    { textField: 'Uruguay', valueField: 'UY' },
    { textField: 'Uzbekistan', valueField: 'UZ' },
    { textField: 'Vanuatu', valueField: 'VU' },
    { textField: 'Venezuela', valueField: 'VE' },
    { textField: 'Viet Nam', valueField: 'VN' },
    { textField: 'Virgin Islands (British)', valueField: 'VG' },
    { textField: 'Virgin Islands (U.S.)', valueField: 'VI' },
    { textField: 'Wallis and Futuna', valueField: 'WF' },
    { textField: 'Western Sahara', valueField: 'EH' },
    { textField: 'Yemen', valueField: 'YE' },
    { textField: 'Zambia', valueField: 'ZM' },
    { textField: 'Zimbabwe', valueField: 'ZW' },
];
