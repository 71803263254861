import React from 'react';
import PropTypes from 'prop-types';
import getIconClassName from '../utils/getIconClassAndDescription';
import { getDateFormattedForEvents } from '../utils/dateFormatUtils';
import getLocationInfo from '../utils/getLocationInfo';

function getDateComponent(date) {
    return !date ? null : (
        <p className="bag-event-icon-info-container__date">
            {getDateFormattedForEvents(date)}
        </p>
    );
}

export default function BagEventIconInfo(props) {
    const { description, imageUrl, imageUrlPrevious } = getIconClassName(
        props.event,
        props.labels
    );
    const imageSrc = props.isPrevious ? imageUrlPrevious : imageUrl;

    return (
        <div className="bag-event-icon-info-container">
            <div className="bag-event-icon-info-container__icon">
                <img alt={props.event.type} src={imageSrc} />
            </div>
            <div className="bag-event-icon-info-container__info">
                {getDateComponent(props.event.date)}
                <p className="bag-event-icon-info-container__description">
                    {`${description} `}
                </p>
                <p className="bag-event-icon-info-container__location">
                    {getLocationInfo(props.event)}
                </p>
            </div>
        </div>
    );
}

BagEventIconInfo.propTypes = {
    isPrevious: PropTypes.bool,
    labels: PropTypes.instanceOf(Object).isRequired,
    event: PropTypes.instanceOf(Object).isRequired,
};

BagEventIconInfo.defaultProps = {
    isPrevious: false,
};
