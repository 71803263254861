import React from 'react';
import PropTypes from 'prop-types';

const customerLogo = require('../images/customerLogo.png');

export default function Header({ headerClass }) {
    return (
        <div className={headerClass}>
            <div className="header__image-container">
                <img
                    src={customerLogo}
                    className="header__customer-image"
                    alt="customer logo"
                />
            </div>
        </div>
    );
}

Header.propTypes = {
    headerClass: PropTypes.string,
};

Header.defaultProps = {
    headerClass: 'header',
};
