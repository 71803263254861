import _ from 'lodash';

function areAllTheFlightsTheSame(flightLegs) {
    return flightLegs.every((leg) => _.isEqual(leg, flightLegs[0]));
}

export function getStationRoute(route) {
    if (_.isEmpty(route)) {
        return '';
    }

    let stringRoute = '';
    route.forEach((leg, index) => {
        if (index === 0) {
            stringRoute += `${leg.flightDepartureStationCode} > ${leg.flightArrivalStationCode}`;
            return;
        }
        stringRoute += ` > ${leg.flightArrivalStationCode}`;
    });
    return stringRoute;
}

export function getRouteForPassengerHeader(passengers) {
    const flightLegs = passengers.reduce((previousValue, currentValue) => {
        if (currentValue.bags.length) {
            const route = currentValue.bags.map((bag) => bag.route);
            return [...previousValue, ...route];
        }
        return previousValue;
    }, []);

    if (!flightLegs[0]) {
        return [];
    }

    return areAllTheFlightsTheSame(flightLegs) ? flightLegs[0] : [];
}
