import { InfoAlert } from 'brock-react-alerts';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Header from '../components/Header';
import Info from '../components/Info';
import InformationModal from '../components/InformationModal';
import TextInput from '../components/TextInput';
import { isInputContainingInvalidCharacters } from '../utils/validationUtil';

export default class LandingPage extends Component {
    state = {
        surname: '',
        passengerPNR: '',
        ticksSinceEpoch: 1,
        validationError: '',
        isModalOpen: false,
    };

    componentDidMount() {
        this.props.resetInitialState();
    }

    handleChange = (stateKey) => (event) => {
        if (!isInputContainingInvalidCharacters(event.target.value)) {
            this.setState({
                [stateKey]: event.target.value.toUpperCase().trim(),
            });
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.state.passengerPNR) {
            event.target.querySelectorAll('input')[0].focus();
            return;
        }

        if (!this.state.passengerPNR.startsWith('2')) {
            this.setState({
                validationError:
                    'Please verify that Booking Reference begins with "2"',
            });
            event.target.querySelectorAll('input')[0].focus();
            return;
        }

        this.setState({ validationError: '' });

        if (!this.state.surname) {
            event.target.querySelectorAll('input')[1].focus();
            return;
        }

        this.props.fetchPassengerIdentification(this.state);
    };

    renderInfoAlert = () => {
        if (
            (!this.state.validationError && !this.props.responseMessage) ||
            this.props.isRefreshing
        ) {
            return null;
        }

        const message =
            this.state.validationError || this.props.responseMessage;

        return <InfoAlert>{message}</InfoAlert>;
    };

    render() {
        return (
            <div className="app">
                <div className="landing-page">
                    <Header headerClass="landing-page__header" />
                    <div className="landing-page__body">
                        <p className="landing-page__title">
                            {this.props.labels.landingPageTitle}
                        </p>
                        <Info info={this.props.labels.landingPageSubTitle} />
                        {this.renderInfoAlert()}
                        <div className="landing-page__form-container">
                            <Info info={this.props.labels.landingPageInfo} />
                            <b>
                                <Info
                                    info={
                                        this.props.labels
                                            .landingPageInfoWarningText
                                    }
                                />
                            </b>
                            <form
                                className="landing-page__form"
                                onSubmit={this.handleSubmit}
                            >
                                <TextInput
                                    autoFocus={true}
                                    label={this.props.labels.bookingReference}
                                    value={this.state.passengerPNR}
                                    onChange={this.handleChange('passengerPNR')}
                                />
                                <TextInput
                                    label={this.props.labels.familyName}
                                    value={this.state.surname}
                                    onChange={this.handleChange('surname')}
                                />
                                <div className="landing-page__submit-button-container">
                                    <input
                                        className="customer-button customer-button--primary landing-page__submit-button"
                                        type="submit"
                                        value={
                                            this.props.isRefreshing
                                                ? this.props.labels
                                                      .searchInProgress
                                                : this.props.labels.search
                                        }
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="landing-page__information-link">
                            <button
                                className="button-link customer-link"
                                onClick={() =>
                                    this.setState({ isModalOpen: true })
                                }
                                type="button"
                            >
                                {this.props.labels.findBookingReference}
                            </button>
                        </div>
                        <a
                            className="customer-link"
                            href={this.props.labels.generalBagPolicyURL}
                            // eslint-disable-next-line react/jsx-no-target-blank
                            target="_blank"
                        >
                            {this.props.labels.generalBagPolicy}
                        </a>
                    </div>
                </div>
                {this.state.isModalOpen && (
                    <InformationModal
                        closeModal={() => this.setState({ isModalOpen: false })}
                        labels={this.props.labels}
                    />
                )}
            </div>
        );
    }
}

LandingPage.propTypes = {
    labels: PropTypes.instanceOf(Object).isRequired,
    isRefreshing: PropTypes.bool.isRequired,
    responseMessage: PropTypes.string.isRequired,
    resetInitialState: PropTypes.func.isRequired,
    fetchPassengerIdentification: PropTypes.func.isRequired,
};
