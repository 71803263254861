import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Passenger from '../components/Passenger';
import BagEventIconInfo from './BagEventIconInfo';
import getQueryParam from '../utils/getQueryParam';

function renderLatestEventPerBag(bag, location, labels, index) {
    const { events, baggageTagNumber } = bag;
    const latestEvent = events[0];

    if (!latestEvent) {
        return (
            <div key={index} className="latest-events__event-container">
                <div className="bag__info-container">
                    <i className="icon-bag_white" />
                    {`${labels.tagNumber}: ${baggageTagNumber}`}
                </div>
                <div className="latest-events__bag-event">
                    <p className="latest-events__no-declarations-message">
                        No declarations found for this bag
                    </p>
                    <i className="icon-chevron latest-events__more-info-icon" />
                </div>
            </div>
        );
    }

    return (
        <Link
            to={`BagEvents/${baggageTagNumber}?${getQueryParam(location)}`}
            className="latest-events__details-link"
        >
            <div key={index} className="latest-events__event-container">
                <div className="bag__info-container">
                    <i className="icon-bag_white" />
                    {`${labels.tagNumber}: ${baggageTagNumber}`}
                </div>
                <div className="latest-events__bag-event">
                    <BagEventIconInfo labels={labels} event={latestEvent} />
                    <i className="icon-chevron latest-events__more-info-icon" />
                </div>
            </div>
        </Link>
    );
}

function renderPassenger(passenger, location, labels, index) {
    return (
        <Fragment
            key={`${passenger.passengerName}${passenger.passengerPNR}${index}`}
        >
            <Passenger {...passenger} labels={labels} />
            {passenger.bags.map((bag, index) =>
                renderLatestEventPerBag(bag, location, labels, index)
            )}
        </Fragment>
    );
}

export default function LatestEvents(props) {
    const { passengers = [], location, labels } = props;
    return (
        <div className="latest-events">
            {passengers.map((passenger, index) =>
                renderPassenger(passenger, location, labels, index)
            )}
        </div>
    );
}

LatestEvents.propTypes = {
    labels: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    passengers: PropTypes.instanceOf(Array).isRequired,
};
