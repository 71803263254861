import React from 'react';
import PropTypes from 'prop-types';
import BagEventIconInfo from '../containers/BagEventIconInfo';
import { getDateFormattedForEvents } from '../utils/dateFormatUtils';

function renderEvent(event, index, events, labels) {
    const isPrevious = index > 0;
    const previousClassName = isPrevious ? 'bag__bag-event--previous' : '';
    const previousDateClassName = isPrevious
        ? 'bag__bag-event-date--previous'
        : '';
    const { date, ...remainingEvents } = event;
    const lastElementClassName =
        index === events.length - 1 ? 'bag__bag-event--last' : '';
    const iconClass = isPrevious ? 'icon-circle' : 'icon-circle_filled';

    return (
        <div
            className="bag__bag-events-container"
            key={`bag__bag-events-container-${index}`}
        >
            <div
                className={`bag__bag-event ${previousClassName} ${lastElementClassName}`}
            >
                <i className={`${iconClass} bag__bag-event-date-icon`} />
                <p className={`bag__bag-event-date ${previousDateClassName}`}>
                    {getDateFormattedForEvents(date)}
                </p>
                <BagEventIconInfo
                    isPrevious={isPrevious}
                    labels={labels}
                    event={remainingEvents}
                />
            </div>
        </div>
    );
}

export default function EventsPerBag(props) {
    const { baggageTagNumber, events } = props.bagToRender;
    return (
        <div className="bag">
            <div className="bag__info-container">
                <i className="icon-bag_white" />
                {`${props.labels.tagNumber}: ${baggageTagNumber}`}
            </div>
            <div className="bag__list-container">
                {events.map((event, index, events) =>
                    renderEvent(event, index, events, props.labels)
                )}
            </div>
        </div>
    );
}

EventsPerBag.propTypes = {
    bagToRender: PropTypes.instanceOf(Object).isRequired,
    labels: PropTypes.instanceOf(Object).isRequired,
};
