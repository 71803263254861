import React, { useRef, useState } from 'react';
import {
    ButtonSecondary,
    HorizontalFlex,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    VerticalFlex,
} from 'smartsuite-ui';

import { steps } from '../constants/bagTypes';
import claimLabels from '../locales/en/claimLabels';
import PanelIcon from './PanelIcon';

const firstStep = 'luggageMiscellaneous';

function BagTypeModal({ closeModal, tagNumber, updateType }) {
    const [currentStep, setCurrentStep] = useState(firstStep);
    const stepStack = useRef([]);

    function onTypeSelect(type) {
        updateType(tagNumber, type);
        closeModal();
    }

    function onBackClick() {
        if (stepStack.current.length === 0) {
            closeModal();
        }

        const previousStep = stepStack.current.pop();
        setCurrentStep(previousStep);
    }

    function onOptionClick(option) {
        if (option.nextStep) {
            stepStack.current.push(currentStep);
            setCurrentStep(option.nextStep);
        } else {
            onTypeSelect(option);
        }
    }

    const step = steps.find((step) => step.name === currentStep);

    return (
        <Modal onOverlayClick={closeModal}>
            <ModalHeader onDismiss={closeModal}>
                {claimLabels[step.name]}
            </ModalHeader>
            <ModalBody>
                <HorizontalFlex cssClassModifiers={['justify-center']}>
                    <VerticalFlex>
                        {step.options.map((option) => (
                            <PanelIcon
                                key={option.text}
                                icon={option.src}
                                text={claimLabels[option.text]}
                                onClick={() => onOptionClick(option)}
                            />
                        ))}
                    </VerticalFlex>
                </HorizontalFlex>
            </ModalBody>
            <ModalFooter>
                <HorizontalFlex>
                    <ButtonSecondary onClick={onBackClick}>
                        {claimLabels.back}
                    </ButtonSecondary>
                </HorizontalFlex>
            </ModalFooter>
        </Modal>
    );
}

export default BagTypeModal;
