import React from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    PadContainer,
    VerticalFlex,
} from 'smartsuite-ui';

function InformationModal({ closeModal, labels }) {
    return (
        <Modal onOverlayClick={closeModal}>
            <ModalHeader onDismiss={closeModal}>
                {labels.informationModalTitle}
            </ModalHeader>
            <ModalBody>
                <div className="information-modal">
                    <PadContainer
                        cssClassModifiers={['pad-medium', 'no-pad-bottom']}
                    >
                        {labels.informationModalText1}
                    </PadContainer>
                    <ul>
                        <li>
                            <VerticalFlex>
                                <b>{labels.informationModalOption1}</b>
                                {labels.informationModalOption1Text}
                            </VerticalFlex>
                        </li>
                        <li>
                            <VerticalFlex>
                                <b>{labels.informationModalOption2}</b>
                                {labels.informationModalOption2Text}
                            </VerticalFlex>
                        </li>
                    </ul>
                    <PadContainer
                        cssClassModifiers={['pad-medium', 'no-pad-top']}
                    >
                        {labels.informationModalText2}
                    </PadContainer>
                    <PadContainer
                        cssClassModifiers={['pad-medium', 'no-pad-top']}
                    >
                        {labels.informationModalText3}
                    </PadContainer>
                    <PadContainer
                        cssClassModifiers={['pad-medium', 'no-pad-top']}
                    >
                        {labels.informationModalText4}
                    </PadContainer>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default InformationModal;
