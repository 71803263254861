import React from 'react';
import PropTypes from 'prop-types';
import { ButtonDefault } from 'brock-react-button';

function getBackLabel({ labels, location }) {
    return Object.prototype.hasOwnProperty.call(
        location.match.params,
        'baggageTagNumber'
    )
        ? labels.backToPassengerInfo
        : labels.backToSearch;
}

function getPreviousPath({ previousPath, location }) {
    return previousPath
        ? () => location.history.push(previousPath)
        : () => location.history.goBack();
}

function getLastRefreshLabel({ labels, lastUpdated, isRefreshing }) {
    return !isRefreshing
        ? `${labels.lastRefresh} ${lastUpdated}`
        : labels.refreshInProgress;
}

export default function Footer(props) {
    return (
        <div className="footer">
            <div>
                <ButtonDefault
                    className="customer-button customer-button--secondary footer__back-button"
                    handleClick={getPreviousPath(props)}
                >
                    {getBackLabel(props)}
                </ButtonDefault>
                <ButtonDefault
                    className="customer-button customer-button--primary"
                    handleClick={props.onRefresh}
                >
                    {props.labels.refresh}
                </ButtonDefault>
                <ButtonDefault
                    className="customer-button customer-button--secondary"
                    handleClick={() => props.location.history.push('/claim')}
                >
                    {props.labels.report}
                </ButtonDefault>
            </div>
            <div className="footer__last-refresh-label">
                {getLastRefreshLabel(props)}
            </div>
        </div>
    );
}

Footer.propTypes = {
    labels: PropTypes.instanceOf(Object).isRequired,
    onRefresh: PropTypes.func.isRequired,
    isRefreshing: PropTypes.bool.isRequired,
    lastUpdated: PropTypes.string.isRequired,
    previousPath: PropTypes.string,
    location: PropTypes.instanceOf(Object),
};

Footer.defaultProps = {
    displayBackButton: false,
    previousPath: '',
};
