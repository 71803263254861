import React from 'react';
import { PadContainer } from 'smartsuite-ui';

function PanelIcon({ icon, onClick, text }) {
    return (
        <div className="panel-icon" onClick={onClick}>
            <img alt={text} className="panel-icon__icon" src={icon} />
            <PadContainer cssClassModifiers={['pad-small']} />
            <span>{text}</span>
        </div>
    );
}

export default PanelIcon;
