import React, { useState } from 'react';

import AppWrapper from '../components/AppWrapper';
import { customsFormOptions, views } from '../constants/claimConstants';
import AddressSelectionForm from '../containers/AddressSelectionForm';
import BagInformationForm from '../containers/BagInformationForm';
import ClaimInformation from '../containers/ClaimInformation';
import CustomsForm from '../containers/CustomsForm';
import ReviewForm from '../containers/ReviewForm';
import { ClaimContextProvider } from '../contexts/ClaimContext';

import '../styles/Claim.css';
import '../styles/micrositeIcons.css';

function getInitialView(passengerData) {
    if (!passengerData) {
        return views.claimInformation;
    }

    return passengerData[0].passenger.customsFormDisplayPage ===
        customsFormOptions.reviewScreen
        ? views.review
        : views.claimInformation;
}

function Claim({ location, passengerData }) {
    const [currentView, setCurrentView] = useState(
        getInitialView(passengerData)
    );

    function renderView() {
        switch (currentView) {
            case views.bagInformation: {
                return <BagInformationForm setCurrentView={setCurrentView} />;
            }
            case views.address: {
                return (
                    <AddressSelectionForm
                        customsFormDisplayPage={
                            passengerData[0].passenger.customsFormDisplayPage
                        }
                        setCurrentView={setCurrentView}
                    />
                );
            }
            case views.review: {
                return (
                    <ReviewForm
                        location={location}
                        setCurrentView={setCurrentView}
                    />
                );
            }
            case views.customs: {
                return <CustomsForm setCurrentView={setCurrentView} />;
            }
            default: {
                return (
                    <ClaimInformation
                        customsFormDisplayPage={
                            passengerData[0].passenger.customsFormDisplayPage
                        }
                        location={location}
                        passengerData={passengerData}
                        setCurrentView={setCurrentView}
                    />
                );
            }
        }
    }

    if (!passengerData) {
        location.history.push('/');
        return null;
    }

    return (
        <ClaimContextProvider passengerData={passengerData}>
            <AppWrapper>{renderView()}</AppWrapper>
        </ClaimContextProvider>
    );
}

export default Claim;
