export const views = {
    claimInformation: 'claimInformation',
    bagInformation: 'bagInformation',
    address: 'address',
    review: 'review',
    customs: 'customs',
};

export const claimReducerActions = {
    EMPTY: 'EMPTY',
    UPDATE: 'UPDATE',
};

export const micrositeIcons = {
    bagError: 'microsite-bag-error',
    bagQuestion: 'microsite-bag-question',
    bagSuccess: 'microsite-bag-success',
    noColour: 'microsite-no-colour',
};

export const customsFormOptions = {
    customsForm: 'CustomsForm',
    reviewScreen: 'ReviewScreen',
    none: 'None',
};
