import beige from '../images/colours/BE.svg';
import black from '../images/colours/BK.svg';
import blue from '../images/colours/BU.svg';
import brown from '../images/colours/BN.svg';
import green from '../images/colours/GN.svg';
import grey from '../images/colours/GY.svg';
import multicolour from '../images/colours/MC.svg';
import pattern from '../images/colours/PR.svg';
import purple from '../images/colours/PU.svg';
import red from '../images/colours/RD.svg';
import white from '../images/colours/WT.svg';
import yellow from '../images/colours/YW.svg';

export const bagColours = [
    { text: 'white', src: white, code: 'WT' },
    { text: 'black', src: black, code: 'BK' },
    { text: 'grey', src: grey, code: 'GY' },
    { text: 'blue', src: blue, code: 'BU' },
    { text: 'purple', src: purple, code: 'PU' },
    { text: 'red', src: red, code: 'RD' },
    { text: 'yellow', src: yellow, code: 'YW' },
    { text: 'beige', src: beige, code: 'BE' },
    { text: 'brown', src: brown, code: 'BN' },
    { text: 'green', src: green, code: 'GN' },
    { text: 'multicolour', src: multicolour, code: 'MC' },
    { text: 'pattern', src: pattern, code: 'PR' },
];
