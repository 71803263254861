import { claimReducerActions } from '../constants/claimConstants';

export const initialState = {};

export function claimReducer(state, action) {
    switch (action.type) {
        case claimReducerActions.EMPTY:
            return initialState;
        case claimReducerActions.UPDATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
