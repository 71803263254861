import React from 'react';
import {
    HorizontalFlex,
    Modal,
    ModalBody,
    ModalHeader,
    VerticalFlex,
} from 'smartsuite-ui';
import { bagColours } from '../constants/bagColours';
import claimLabels from '../locales/en/claimLabels';
import PanelIcon from './PanelIcon';

function BagColourModal({ closeModal, tagNumber, updateColour }) {
    return (
        <Modal onOverlayClick={closeModal} size="small">
            <ModalHeader onDismiss={closeModal}>
                {claimLabels.selectColour}
            </ModalHeader>
            <ModalBody>
                <HorizontalFlex cssClassModifiers={['justify-center']}>
                    <VerticalFlex>
                        {bagColours.map((colour) => (
                            <PanelIcon
                                key={colour.text}
                                icon={colour.src}
                                text={claimLabels[colour.text]}
                                onClick={() => {
                                    updateColour(tagNumber, colour);
                                    closeModal();
                                }}
                            />
                        ))}
                    </VerticalFlex>
                </HorizontalFlex>
            </ModalBody>
        </Modal>
    );
}

export default BagColourModal;
