import React from 'react';
import {
    Modal,
    ModalBody,
    ModalFooterConfirmDismiss,
    ModalHeader,
    PadContainer,
} from 'smartsuite-ui';
import claimLabels from '../locales/en/claimLabels';

function ConfirmationModal({ onConfirm, onDismiss }) {
    return (
        <Modal onOverlayClick={onDismiss} size="small">
            <ModalHeader onDismiss={onDismiss}>
                {claimLabels.discardClaim}
            </ModalHeader>
            <ModalBody>
                <PadContainer>
                    {claimLabels.discardClaimConfirmation}
                </PadContainer>
            </ModalBody>
            <ModalFooterConfirmDismiss
                confirmLabel={claimLabels.yes}
                dismissLabel={claimLabels.no}
                onConfirm={onConfirm}
                onDismiss={onDismiss}
            />
        </Modal>
    );
}

export default ConfirmationModal;
