import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {
    Button,
    ButtonSecondary,
    ButtonWithIcon,
    Checkbox,
    DateTimePicker,
    Fields,
    HorizontalFlex,
    icons,
    Input,
    PadContainer,
    RadioGroup,
    SingleSelect,
    Title,
    TypedInput,
    Types,
} from 'smartsuite-ui';
import FormFooter from '../components/FormFooter';
import FormWrapper from '../components/FormWrapper';
import { views } from '../constants/claimConstants';
import { countries } from '../constants/countries';
import { useClaimContext } from '../contexts/ClaimContext';
import claimLabels from '../locales/en/claimLabels';

const options = {
    yes: 'yes',
    no: 'no',
};

function CustomsForm({ setCurrentView }) {
    const { claim, submitClaim } = useClaimContext();
    const [nationality, setNationality] = useState();
    const [countryOfOrigin, setCountryOfOrigin] = useState();
    const [isIreland, setIsIreland] = useState();
    const [irelandDepartureDate, setIrelandDepartureDate] = useState();
    const [questionOne, setQuestionOne] = useState();
    const [questionTwo, setQuestionTwo] = useState();
    const [questionThree, setQuestionThree] = useState();
    const [isAgreed, setIsAgreed] = useState(false);
    const [isSigned, setIsSigned] = useState(false);
    const [prohibitedGoods, setProhibitedGoods] = useState([
        {
            id: 1,
            description: '',
            quantity: '',
            country: '',
            cost: '',
        },
    ]);
    const [exciseGoods, setExciseGoods] = useState([
        {
            category: claimLabels.customsExcise1,
            description: '',
            quantity: '',
            country: '',
            cost: '',
            isCostDisabled: true,
        },
        {
            category: claimLabels.customsExcise2,
            description: '',
            quantity: '',
            country: '',
            cost: '',
            isCostDisabled: true,
        },
        {
            category: claimLabels.customsExcise3,
            description: '',
            quantity: '',
            country: '',
            cost: '',
            isCostDisabled: true,
        },
        {
            category: claimLabels.customsExcise4,
            description: '',
            quantity: '',
            country: '',
            cost: '',
            isCostDisabled: true,
        },
        {
            category: claimLabels.customsExcise5,
            description: '',
            quantity: '',
            country: '',
            cost: '',
            isCostDisabled: true,
        },
        {
            category: claimLabels.customsExcise6,
            description: '',
            quantity: '',
            country: '',
            cost: '',
        },
    ]);

    const sigRef = useRef();

    function onSubmit() {
        const isProhibitedGoods = questionOne === options.yes;
        const isExciseGoods = questionTwo === options.yes;
        const isInExcessOfMonetaryAllowances = questionThree === options.yes;
        const isVisitingIreland = isIreland === options.yes;

        const customs = {
            nationality,
            countryOfOrigin,
            isVisitingIreland,
            irelandDepartureDate,
            passengerSignature: sigRef.current.getTrimmedCanvas().toDataURL(),
            isProhibitedGoods,
            isExciseGoods,
            isInExcessOfMonetaryAllowances,
            prohibitedGoods: isProhibitedGoods ? prohibitedGoods : null,
            exciseGoods:
                isExciseGoods || isInExcessOfMonetaryAllowances
                    ? exciseGoods
                    : null,
        };

        submitClaim(claim, customs);
        setCurrentView(views.claimInformation);
    }

    function onProhibitedGoodChange(goodId, fieldId, value) {
        setProhibitedGoods((prevState) =>
            prevState.map((good) =>
                good.id === goodId ? { ...good, [fieldId]: value } : good
            )
        );
    }

    function onExciseGoodChange(category, fieldId, value) {
        setExciseGoods((prevState) =>
            prevState.map((good) =>
                good.category === category
                    ? { ...good, [fieldId]: value }
                    : good
            )
        );
    }

    function addGood() {
        setProhibitedGoods((prevState) => [
            ...prevState,
            {
                id: prevState.length + 1,
                description: '',
                quantity: '',
                country: '',
                cost: '',
            },
        ]);
    }

    function isFormValid() {
        let isValid =
            nationality &&
            countryOfOrigin &&
            isIreland &&
            questionOne &&
            questionTwo &&
            questionThree &&
            isAgreed &&
            isSigned;

        if (isIreland === options.yes) {
            isValid = isValid && irelandDepartureDate;
        }

        if (questionOne === options.yes) {
            isValid =
                isValid &&
                prohibitedGoods[0].description &&
                prohibitedGoods[0].quantity &&
                prohibitedGoods[0].country &&
                prohibitedGoods[0].cost;
        }

        if (questionTwo === options.yes || questionThree === options.yes) {
            isValid =
                isValid &&
                exciseGoods.some(
                    (good) => good.description && good.quantity && good.country
                );
        }

        return isValid;
    }

    return (
        <FormWrapper title={claimLabels.titleCustomsForm}>
            <div className="form-fields">
                <Fields cssClassModifiers={['no-background', 'column']}>
                    <PadContainer
                        cssClassModifiers={['pad-small', 'no-pad-top']}
                    >
                        <span className="customs-form__instruction">
                            {claimLabels.passengerInformation}
                        </span>
                    </PadContainer>
                    <SingleSelect
                        cssClassModifiers={['half-relative']}
                        isClearable={false}
                        label={claimLabels.customsNationality}
                        noOptionsMessage={() => claimLabels.noResults}
                        options={countries}
                        onChange={(event) => setNationality(event.textField)}
                        textField="textField"
                        value={countries.find(
                            (country) => country.textField === nationality
                        )}
                        valueField="valueField"
                    />
                    <SingleSelect
                        cssClassModifiers={['half-relative']}
                        isClearable={false}
                        label={claimLabels.customsCountryOfOrigin}
                        noOptionsMessage={() => claimLabels.noResults}
                        options={countries}
                        onChange={(event) =>
                            setCountryOfOrigin(event.textField)
                        }
                        textField="textField"
                        value={countries.find(
                            (country) => country.textField === countryOfOrigin
                        )}
                        valueField="valueField"
                    />
                    <PadContainer cssClassModifiers={['pad-small']}>
                        <span className="customs-form__instruction">
                            {claimLabels.customsIsIrelandHeader}
                        </span>
                    </PadContainer>
                    <div className="customs-form__question">
                        <span>{`${claimLabels.customsIsIreland}`}</span>
                        <RadioGroup
                            options={Object.keys(options).map((option) => ({
                                textField: claimLabels[option],
                                valueField: option,
                            }))}
                            value={isIreland}
                            onChange={(event) =>
                                setIsIreland(event.target.value)
                            }
                        />
                    </div>
                    {isIreland === options.yes && (
                        <DateTimePicker
                            label={claimLabels.customsIrelandDepartureDate}
                            min={new Date()}
                            onChange={setIrelandDepartureDate}
                            time={false}
                            value={irelandDepartureDate}
                        />
                    )}
                </Fields>
            </div>
            <div className="customs-form__instruction">
                <PadContainer cssClassModifiers={['no-pad-left-right']}>
                    {claimLabels.instructionCustomsForm1}
                </PadContainer>
            </div>

            <div className="customs-form__fields">
                <PadContainer
                    cssClassModifiers={['pad-medium', 'no-pad-bottom']}
                >
                    <PadContainer
                        cssClassModifiers={['pad-small', 'no-pad-top-bottom']}
                    >
                        <span className="customs-form__instruction">
                            {claimLabels.customsQuestion}
                        </span>
                    </PadContainer>
                    <div className="customs-form__question">
                        <span>{`1. ${claimLabels.customsQuestion1}`}</span>
                        <RadioGroup
                            options={Object.keys(options).map((option) => ({
                                textField: claimLabels[option],
                                valueField: option,
                            }))}
                            value={questionOne}
                            onChange={(event) =>
                                setQuestionOne(event.target.value)
                            }
                        />
                    </div>
                </PadContainer>
            </div>

            {questionOne === options.yes && (
                <PadContainer cssClassModifiers={['no-pad-left-right']}>
                    {prohibitedGoods.map((good) => (
                        <div key={good.id} className="form-fields">
                            <Fields cssClassModifiers={['no-background']}>
                                <Input
                                    cssClassModifiers={['half-relative']}
                                    label={claimLabels.description}
                                    maxLength={255}
                                    onChange={(event) =>
                                        onProhibitedGoodChange(
                                            good.id,
                                            'description',
                                            event.target.value
                                        )
                                    }
                                    value={good.description || ''}
                                />
                                <TypedInput
                                    cssClassModifiers={['half-relative']}
                                    label={claimLabels.quantity}
                                    maxLength={10}
                                    onChange={(event) =>
                                        onProhibitedGoodChange(
                                            good.id,
                                            'quantity',
                                            event
                                        )
                                    }
                                    type={Types.Int64Positive}
                                    value={good.quantity || ''}
                                />
                            </Fields>
                            <Fields cssClassModifiers={['no-background']}>
                                <SingleSelect
                                    cssClassModifiers={['half-relative']}
                                    isClearable={false}
                                    label={claimLabels.customsGoodsCountry}
                                    noOptionsMessage={() =>
                                        claimLabels.noResults
                                    }
                                    options={countries}
                                    onChange={(event) =>
                                        onProhibitedGoodChange(
                                            good.id,
                                            'country',
                                            event.textField
                                        )
                                    }
                                    textField="textField"
                                    value={countries.find(
                                        (country) =>
                                            country.textField === good.country
                                    )}
                                    valueField="valueField"
                                />
                                <TypedInput
                                    cssClassModifiers={['half-relative']}
                                    label={claimLabels.customsCost}
                                    maxLength={13}
                                    onChange={(event) =>
                                        onProhibitedGoodChange(
                                            good.id,
                                            'cost',
                                            event
                                        )
                                    }
                                    type={Types.Int64Positive}
                                    value={good.cost || ''}
                                />
                            </Fields>
                        </div>
                    ))}
                    {prohibitedGoods.length < 5 && (
                        <HorizontalFlex
                            cssClassModifiers={['justify-flex-end']}
                        >
                            <PadContainer
                                cssClassModifiers={[
                                    'no-pad-left-right',
                                    'no-pad-bottom',
                                ]}
                            >
                                <ButtonWithIcon
                                    iconClass={icons.plus}
                                    onClick={addGood}
                                />
                            </PadContainer>
                        </HorizontalFlex>
                    )}
                </PadContainer>
            )}

            <div className="customs-form__fields">
                <PadContainer cssClassModifiers={['pad-medium', 'no-pad-top']}>
                    <div className="customs-form__question">
                        <span>{`2. ${claimLabels.customsQuestion2}`}</span>
                        <RadioGroup
                            options={Object.keys(options).map((option) => ({
                                textField: claimLabels[option],
                                valueField: option,
                            }))}
                            value={questionTwo}
                            onChange={(event) =>
                                setQuestionTwo(event.target.value)
                            }
                        />
                    </div>
                    <div className="customs-form__question">
                        <span>{`3. ${claimLabels.customsQuestion3}`}</span>
                        <RadioGroup
                            options={Object.keys(options).map((option) => ({
                                textField: claimLabels[option],
                                valueField: option,
                            }))}
                            value={questionThree}
                            onChange={(event) =>
                                setQuestionThree(event.target.value)
                            }
                        />
                    </div>
                </PadContainer>
            </div>

            {(questionTwo === options.yes || questionThree === options.yes) && (
                <PadContainer cssClassModifiers={['no-pad-left-right']}>
                    {exciseGoods.map((good) => (
                        <div key={good.category} className="form-fields">
                            <PadContainer
                                cssClassModifiers={[
                                    'pad-medium',
                                    'no-pad-bottom',
                                ]}
                            >
                                <PadContainer cssClassModifiers={['pad-small']}>
                                    <div className="customs-form__instruction">
                                        {good.category}
                                    </div>
                                </PadContainer>
                            </PadContainer>
                            <Fields
                                cssClassModifiers={[
                                    'no-background',
                                    'no-pad-top',
                                ]}
                            >
                                <Input
                                    cssClassModifiers={['half-relative']}
                                    label={claimLabels.description}
                                    maxLength={255}
                                    onChange={(event) =>
                                        onExciseGoodChange(
                                            good.category,
                                            'description',
                                            event.target.value
                                        )
                                    }
                                    value={good.description || ''}
                                />
                                <TypedInput
                                    cssClassModifiers={['half-relative']}
                                    label={claimLabels.quantity}
                                    maxLength={10}
                                    onChange={(event) =>
                                        onExciseGoodChange(
                                            good.category,
                                            'quantity',
                                            event
                                        )
                                    }
                                    type={Types.Int64Positive}
                                    value={good.quantity || ''}
                                />
                            </Fields>
                            <Fields cssClassModifiers={['no-background']}>
                                <SingleSelect
                                    cssClassModifiers={['half-relative']}
                                    isClearable={false}
                                    label={claimLabels.customsGoodsCountry}
                                    noOptionsMessage={() =>
                                        claimLabels.noResults
                                    }
                                    options={countries}
                                    onChange={(event) =>
                                        onExciseGoodChange(
                                            good.category,
                                            'country',
                                            event.textField
                                        )
                                    }
                                    textField="textField"
                                    value={countries.find(
                                        (country) =>
                                            country.textField === good.country
                                    )}
                                    valueField="valueField"
                                />
                                <TypedInput
                                    cssClassModifiers={['half-relative']}
                                    disabled={good.isCostDisabled}
                                    label={claimLabels.customsCost}
                                    maxLength={13}
                                    onChange={(event) =>
                                        onExciseGoodChange(
                                            good.category,
                                            'cost',
                                            event
                                        )
                                    }
                                    type={Types.Int64Positive}
                                    value={good.cost || ''}
                                />
                            </Fields>
                        </div>
                    ))}
                </PadContainer>
            )}

            <div className="customs-form__instruction">
                <PadContainer cssClassModifiers={['no-pad-left-right']}>
                    {claimLabels.instructionCustomsForm2}
                </PadContainer>
            </div>

            <Title cssClassModifiers={['size-small']}>
                {claimLabels.customsSignOffHeader}
            </Title>
            <Checkbox
                checked={isAgreed}
                onChange={(event) => setIsAgreed(event.target.checked)}
                checkboxLabel={claimLabels.customsSignOff}
            />
            <HorizontalFlex cssClassModifiers={['align-flex-end', 'flex-wrap']}>
                <div className="customs-form__signature">
                    <SignatureCanvas
                        canvasProps={{
                            width: 500,
                            height: 200,
                        }}
                        onEnd={() => setIsSigned(true)}
                        ref={sigRef}
                    />
                </div>
                <PadContainer cssClassModifiers={['pad-medium']} />
                <ButtonSecondary
                    onClick={() => {
                        setIsSigned(false);
                        sigRef.current.clear();
                    }}
                >
                    {claimLabels.clear}
                </ButtonSecondary>
            </HorizontalFlex>

            <FormFooter
                navigateToPassengerInformation={() =>
                    setCurrentView(views.claimInformation)
                }
            >
                <ButtonSecondary onClick={() => setCurrentView(views.address)}>
                    {claimLabels.back}
                </ButtonSecondary>
                <PadContainer cssClassModifiers={['pad-small']} />
                <Button disabled={!isFormValid()} onClick={onSubmit}>
                    {claimLabels.submit}
                </Button>
            </FormFooter>
        </FormWrapper>
    );
}

export default CustomsForm;
