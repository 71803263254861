import React from 'react';
import {
    AccentLabel,
    HorizontalFlex,
    PadContainer,
    VerticalFlex,
} from 'smartsuite-ui';
import { micrositeIcons } from '../constants/claimConstants';
import claimLabels from '../locales/en/claimLabels';

function renderBagIcon(isSubmitted) {
    const baseClassName = 'bag-ticket__bag-icon';

    if (isSubmitted) {
        return (
            <span
                className={`${baseClassName} ${baseClassName}--success ${micrositeIcons.bagSuccess}`}
            />
        );
    }

    return (
        <span
            className={`${baseClassName} ${baseClassName}--error ${micrositeIcons.bagError}`}
        />
    );
}

function BagTicket({ bag, isClaimSubmitted }) {
    const isSubmitted = bag ? bag.alreadyHasAHL : isClaimSubmitted;

    if (!bag) {
        return (
            <div className="bag-ticket">
                <div className="bag-ticket__header bag-ticket__header--no-bag">
                    <span
                        className={`bag-ticket__bag-icon ${micrositeIcons.bagQuestion}`}
                    />
                </div>
                <div className="bag-ticket__body">
                    <VerticalFlex>
                        <PadContainer cssClassModifiers={['pad-small']}>
                            <span>{claimLabels.noBagInformation1}</span>
                        </PadContainer>
                        <PadContainer cssClassModifiers={['pad-small']}>
                            <span>{claimLabels.noBagInformation2}</span>
                        </PadContainer>
                    </VerticalFlex>
                </div>
            </div>
        );
    }

    return (
        <div className="bag-ticket">
            <div className="bag-ticket__header">
                <VerticalFlex>
                    <AccentLabel>{claimLabels.tagNumber}</AccentLabel>
                    <span>{bag.tagNumber}</span>
                </VerticalFlex>
            </div>
            <div className="bag-ticket__body">
                <HorizontalFlex>
                    {renderBagIcon(isSubmitted)}
                    <PadContainer cssClassModifiers={['pad-small']} />
                    <span>
                        {isSubmitted
                            ? claimLabels.informationSubmitted
                            : claimLabels.baggageDelayed}
                    </span>
                </HorizontalFlex>
            </div>
        </div>
    );
}

export default BagTicket;
