import React from 'react';
import { HorizontalFlex, PadContainer } from 'smartsuite-ui';
import brockLogo from '../images/brockLogo.svg';
import claimLabels from '../locales/en/claimLabels';

const customerLogo = require('../images/customerLogo.png');

function AppWrapper({ children }) {
    return (
        <div className="app-wrapper">
            <div className="header">
                <div className="header__image-container">
                    <img
                        src={customerLogo}
                        className="header__customer-image"
                        alt="customer logo"
                    />
                </div>
            </div>
            {children}
            <PadContainer cssClassModifiers={['pad-small']}>
                <HorizontalFlex cssClassModifiers={['justify-center']}>
                    <span>{claimLabels.poweredBy}</span>
                    <PadContainer cssClassModifiers={['pad-small']} />
                    <img
                        alt="Brock Solutions"
                        className="app-wrapper__product-logo"
                        src={brockLogo}
                    />
                </HorizontalFlex>
            </PadContainer>
        </div>
    );
}

export default AppWrapper;
