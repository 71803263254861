import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Header from '../components/Header';
import HeaderInfo from '../components/HeaderInfo';
import Footer from '../components/Footer';
import LatestEvents from '../containers/LatestEvents';

export default class PassengerInfo extends Component {
    render() {
        return (
            <Fragment>
                <Header headerClass="header" />
                <div className="app">
                    <HeaderInfo
                        passengerHeaderRoute={this.props.passengerHeaderRoute}
                    />
                    <div className="body">
                        <LatestEvents
                            passengers={this.props.passengers}
                            location={this.props.location}
                            labels={this.props.labels}
                        />
                    </div>
                </div>
                <Footer
                    onRefresh={this.props.onRefresh}
                    isRefreshing={this.props.isRefreshing}
                    labels={this.props.labels}
                    lastUpdated={this.props.lastUpdated}
                    previousPath={this.props.previousPath}
                    location={this.props.location}
                />
            </Fragment>
        );
    }
}

PassengerInfo.propTypes = {
    labels: PropTypes.instanceOf(Object).isRequired,
    onRefresh: PropTypes.func.isRequired,
    isRefreshing: PropTypes.bool.isRequired,
    lastUpdated: PropTypes.string.isRequired,
    passengers: PropTypes.array,
    previousPath: PropTypes.string.isRequired,
    location: PropTypes.instanceOf(Object),
    passengerHeaderRoute: PropTypes.instanceOf(Array),
};
