export default {
    BagAcceptedDeclaration: 'Bag Checked In',
    BagCreated: 'Bag Created',
    BagDeliveredToCarouselDeclaration: 'Bag Delivered to Baggage Belt',
    BagItineraryChanged: 'BagItineraryChanged',
    BagLoadedOnAircraftDeclaration: 'Bag Loaded On Aircraft',
    BagMishandledDeclaration: 'Bag Mishandled',
    BagReadyForLoadingDeclaration: 'Bag Ready For Loading',
    BagSeenAtStationDeclaration: 'Transfer Bag Received',
    BagTransferredToCarrierDeclaration: 'Bag Transferred To Carrier',
    backToPassengerInfo: 'Back',
    backToSearch: 'Back to Search',
    bookingReference: 'Booking Reference',
    businessClass: 'BUSINESS CLASS',
    contactUs: 'contact us',
    contactUsEnd: 'using the Baggage phone number',
    contactUsLink: 'https://www.aerlingus.com/support/contact-us/',
    contactUsStart:
        'If your bags are delayed, missing or damaged, please tell a member of staff at the information desk at your final destination, or',
    eventsInfo:
        'View the bag details as it is processed and loaded for the journey',
    familyName: 'Family Name',
    findBookingReference: "Can't find your booking reference?",
    generalBagPolicy: 'Information on missing and delayed bags',
    generalBagPolicyURL:
        'https://www.aerlingus.com/travel-information/baggage-information/missing-baggage/#/tab-0-delayed-or-missing-baggage',
    homeInfo: 'Click on arrow to see full journey of bag',
    informationModalOption1: 'Confirmation email',
    informationModalOption1Text:
        'You should have received this shortly after booking.\nDouble check your email inbox, spam and deleted folders.',
    informationModalOption2: 'Aer Lingus account',
    informationModalOption2Text:
        'If you have an account simply log in to view all your upcoming and past trips.\nYour booking reference will be highlighted here for each trip.',
    informationModalText1:
        'If you have made your booking on aerlingus.com or using our mobile App there are a couple of ways to find your booking reference:',
    informationModalText2:
        'If you made your booking through a third party (such as a travel agent, online agency or other airline) please contact the original sales agent directly.',
    informationModalText3:
        'If you have not received a confirmation email you can contact us on Twitter or Facebook with your full name, flight details and the email used when making the booking.',
    informationModalText4:
        'Unfortunately our social media team will be unable to assist you in the event that you have made changes to your booking through our call centre or if you have booked through a third party.',
    informationModalTitle: "Can't find your booking reference?",
    landingPageInfo: 'Enter your Aer Lingus booking reference to proceed',
    landingPageInfoWarningText: 'If your final flight was not with Aer Lingus, please report your bag to the airline you flew your final flight with',
    landingPageSubTitle: 'Please submit one request per passenger.',
    landingPageTitle: 'Report your delayed bag',
    lastRefresh: 'Last refresh',
    noBagFound: 'no bag found',
    refresh: 'Refresh',
    refreshInProgress: 'Refresh in progress...',
    report: 'Report Delayed Baggage',
    search: 'Search',
    searchInProgress: 'Search in progress...',
    tagNumber: 'Tracking ID',
};
