import declarations from '../constants/declarations';

export default function getIconClassName(event, labels) {
    switch (event.type) {
        case declarations.BagAcceptedDeclaration: {
            return {
                className: 'icon-bag_loading_ready',
                description: labels[event.type],
                imageUrl: require('../images/bag_loading_ready_active.png'),
                imageUrlPrevious: require('../images/bag_loading_ready.png'),
            };
        }

        case declarations.BagDeliveredToCarouselDeclaration: {
            const carouselFriendlyName =
                event.eventData && event.eventData.carouselFriendlyName
                    ? event.eventData.carouselFriendlyName
                    : '';

            return {
                className: 'icon-bag_loading_delivered',
                description: `${labels[event.type]} ${carouselFriendlyName}`,
                imageUrl: require('../images/bag_loading_delivered_active.png'),
                imageUrlPrevious: require('../images/bag_loading_delivered.png'),
            };
        }

        case declarations.BagLoadedOnAircraftDeclaration: {
            return {
                className: 'icon-bag_loading_aircraft',
                description: labels[event.type],
                imageUrl: require('../images/bag_loading_aircraft_active.png'),
                imageUrlPrevious: require('../images/bag_loading_aircraft.png'),
            };
        }

        case declarations.BagReadyForLoadingDeclaration: {
            return {
                className: 'icon-bag_loading_ready',
                description: labels[event.type],
                imageUrl: require('../images/bag_loading_ready_active.png'),
                imageUrlPrevious: require('../images/bag_loading_ready.png'),
            };
        }

        case declarations.BagMishandledDeclaration: {
            return {
                className: 'icon-bag_mishandled',
                description: labels[event.type],
                imageUrl: require('../images/bag_loading_ready_active.png'),
                imageUrlPrevious: require('../images/bag_loading_ready.png'),
            };
        }

        case declarations.BagSeenAtStationDeclaration: {
            return {
                className: 'icon-bag_seen_at_station',
                description: labels[event.type],
                imageUrl: require('../images/bag_loading_delivered_active.png'),
                imageUrlPrevious: require('../images/bag_loading_delivered.png'),
            };
        }

        case declarations.BagTransferredToCarrierDeclaration: {
            return {
                className: 'icon-bag_transferred_to_carrier',
                description: labels[event.type],
                imageUrl: require('../images/bag_loading_ready_active.png'),
                imageUrlPrevious: require('../images/bag_loading_ready.png'),
            };
        }

        default: {
            return {
                className: '',
                description: 'Unknown Event',
                imageUrl: require('../images/bag_loading_ready_active.png'),
                imageUrlPrevious: require('../images/bag_loading_ready.png'),
            };
        }
    }
}
