export default {
    address: 'Address line 1',
    addressLineTwo: 'Suite/Unit/Apt.',
    artPortfolio: 'Art portfolio case',
    audioVideoEquipment: 'Audio/Video/Visual/Photo equipment',
    babyCarriage: 'Full-size baby carriage',
    back: 'Back',
    backpack: 'Backpack/Rucksack',
    baggageDelayed: 'Baggage delayed',
    baggageTrolley: 'Baggage trolley',
    beddingBag: 'Bedding bag',
    beige: 'Beige',
    bicycle: 'Bicycle and/or accessories',
    black: 'Black',
    blue: 'Blue',
    bookingReference: 'Booking Reference',
    box: 'Box',
    boxes: 'Boxes/Cases',
    briefcase: 'Briefcase',
    brown: 'Brown',
    cancel: 'Cancel',
    carSeat: "Child's car/booster seat",
    cellNumber: 'Secondary Phone',
    child: "Infant/Child's item",
    city: 'Town/City',
    clear: 'Clear',
    colour: 'Colour',
    computerEquipment: 'Computer/Communications equipment',
    contactInformation: 'Contact Information',
    contactInformation1: 'More Information',
    contactInformation10: 'Contact us',
    contactInformation11:
        ' for updating your request or if you need extra support.',
    contactInformation12: 'If bags still missing after 72 hours?',
    contactInformation13: 'Please ',
    contactInformation14: 'download our delayed bags questionnaire',
    contactInformation15:
        '. The information you provide us with will help us to speed up the search for your bags.',
    contactInformation2: 'For more information on delayed bags ',
    contactInformation3: 'visit this page',
    contactInformation4: 'Got travel insurance?',
    contactInformation5:
        'Report your bag to your travel insurance company as soon as possible.',
    contactInformation6: 'How to make a claim',
    contactInformation7: 'Find out more about making a claim ',
    contactInformation8: 'here',
    contactInformation9: 'Need to make changes?',
    contactLink:
        'https://wtrweb.worldtracer.aero/WorldTracerWeb/pax.do?airlineCode=EI',
    cooler: 'Ice chest/Cooler',
    cosmeticCase: 'Cosmetic/Beauty case',
    country: 'Country',
    courierBag: 'Courier bag',
    customsCost: 'Cost or est. value in Euro (€)',
    customsCountryOfOrigin: 'What country did you start your journey from?',
    customsExcise1: 'Cigarettes, cigarillos, cigars',
    customsExcise2: 'Other tobacco',
    customsExcise3: 'Spirits (including liqueurs)',
    customsExcise4: 'Wine',
    customsExcise5: 'Beer',
    customsExcise6: 'All other goods',
    customsGoodsCountry: 'Country where obtained',
    customsIrelandDepartureDate:
        'What is your planned date of departure from Ireland',
    customsIsIreland: 'Are you a visitor to Ireland?',
    customsNationality: 'Enter your country of nationality',
    customsQuestion: 'Does the baggage listed contain the following goods?',
    customsQuestion1: 'Prohibited or restricted goods',
    customsQuestion2: 'Excise goods',
    customsQuestion3:
        'Goods in excess of the monetary allowances indicated in paragraph 4 (i) of notice 1878A',
    customsSignOff:
        'I have read and agreed to the terms specified in the declaration',
    customsSignOffHeader: 'CUSTOMS DECLARATION SIGN-OFF',
    deliveryAddress: 'Delivery Address',
    description: 'Description',
    discardClaim: 'Discard and leave',
    discardClaimConfirmation:
        'Are you sure you want to leave? Your changes will be lost.',
    diveBag: 'Dive bag/equipment',
    documentCase: 'Document case',
    duffel: 'Duffel/Sport bag',
    dutyFreeItems: 'Duty-free items',
    electricalAppliance: 'Electrical appliance',
    email: 'Primary Email Address',
    fileNumber: 'Tracing Number',
    firearm: 'Firearm(s)',
    fishingRod: 'Fishing rod',
    flightDate: 'Flight Date',
    foldingChair: 'Folding chair',
    frequentFlyerNumber: 'Frequent Flyer Number',
    garmentBag: 'Garment bag/Suit carrier',
    golfBag: 'Golf bag and/or clubs',
    green: 'Green',
    grey: 'Grey',
    hatBox: 'Hat box',
    horizontalExpandable: 'Horizontal design, expandable',
    horizontalHardshell: 'Horizontal design, hard shell',
    horizontalNonExpandable: 'Horizontal design, non expandable',
    horizontalSuitcase: 'Horizontal design',
    informationSubmitted: 'Information submitted',
    inputInvalid: 'you entered is invalid',
    inputTooShort1: 'Enter at least',
    inputTooShort2: 'characters',
    instructionAddressSelectionForm:
        "Tell us the delivery address for your bag and your contact information so we can update you on the bag's tracing status.",
    instructionAddressSelectionFormContact:
        'Please provide your contact details so we can keep you updated.',
    instructionBagInformationForm:
        "Let us know the colour and type of your lost bags, to help our team identify them.\n\nIf you're not sure of the colour or type of each bag then select the best match.",
    instructionCustomsForm1:
        'Before answering the following questions please read Public Notice 1878A which gives detailed information for travelers arriving in Ireland from outside the European Union',
    instructionCustomsForm2:
        'WARNING: Baggage is examined by Customs and there are heavy penalties for making false declarations',
    instructionGeneralError:
        'At this time, we cannot determine the status of your bag',
    instructionPassengerSubmitted1:
        "Thanks for submitting this information.\n\nYou will next receive an email with your bag's Tracing Number plus other useful information.\n\nYou can use this Tracing Number to ",
    instructionPassengerSubmitted2: 'trace your bag',
    instructionReviewForm:
        'In order to deliver your baggage to your chosen address, you may be required to complete a local custom declaration form. Please check with Custom Officials at your arrival airport.',
    instructionSubmitClaim:
        "Tick the bag tag numbers for the bags you are missing.\nAll bags selected here will be sent to the address you'll provide later.\n\nIf you wish to send bags to different addresses, please submit a separate form for each address.",
    isTemporary:
        'I will be at this address for a limited time (temporary address)',
    item: 'Bag',
    laptop: 'Laptop/Overnight bag',
    luggage: 'Luggage/Bags',
    luggageMiscellaneous: 'Select type',
    mattedWovenBag: 'Matted woven bag',
    militaryStyleBag: 'Military style bag',
    miscellaneous: 'Miscellaneous/Other item',
    mobility: 'Medical/mobility/wheeled device',
    multicolour: 'Multicolour',
    musicalInstrument: 'Musical instrument',
    next: 'Next',
    no: 'No',
    noBagInformation1:
        "We're sorry, an issue prevents us from retrieving the information on your delayed bags.",
    noBagInformation2:
        'Please see an Aer Lingus agent for assistance before leaving the airport.',
    noResults: 'No results',
    noZipper: 'My bag closes without a zipper',
    noZipperBags: 'Bags without a zipper',
    optional: 'Optional',
    orthopaedicDevice: 'Orthopaedic device',
    other: 'Other',
    otherChildEquipment: "Other infant/child's item",
    otherSportEquipment: 'Other sports/outdoor equipment',
    pageTitle: 'Aer Lingus - Bag Recovery',
    passengerInformation: 'Passenger information',
    pattern: 'Pattern',
    permanentAddress: 'Permanent Address',
    petCarrier: 'Pet carrier',
    phoneNumber: 'Cell/Mobile Number (Including Country Code)',
    phoneNumberPlaceholder: 'Example 0044 868129606',
    plasticLaundryBag: 'Plastic/Laundry bag',
    postalCode: 'Postal/ZIP Code',
    poweredBy: 'Powered by',
    purple: 'Purple',
    quantity: 'Quantity',
    red: 'Red',
    reportBaggage: 'Report Bags',
    reviewCheckbox:
        'I understand my baggage may not be delivered without a custom form being completed - if it is required - and handed back to Aer Lingus agents',
    securityRemovedItem: 'Item removed by security',
    selectColour: 'Select Colour',
    selectType: 'Select Type',
    shoppingBag: 'Shopping bag (all materials)',
    skiBoots: 'Ski boots/boot bag',
    skis: 'Skis and/or poles',
    sleepingBag: 'Sleeping bag/bedroll/tent',
    snowboard: 'Snowboard/Sled',
    sportEquipment: 'Sports/Outdoor equipment',
    state: 'County/State/Province',
    storageContainer: 'Storage container',
    submit: 'Submit Report',
    surfEquipment: 'Surf equipment',
    tagNumber: 'Tag number',
    temporaryAddressValidUntil: 'I will be at this temporary address until',
    titleAddressSelectionForm: 'Provide your details',
    titleBagInformationForm: 'Describe your bag',
    titleCustomsForm: 'Customs Declaration',
    titleGeneralError: '',
    titlePassengerSubmitted: 'Your report was submitted',
    titleSubmitClaim: 'Select your missing bags',
    toolBox: 'Tool/tackle box',
    trunk: 'Trunk/Sample display case',
    tube: 'Tube not containing sports equipment',
    type: 'Type',
    umbrella: 'Umbrella (all types)',
    umbrellaStroller: 'Umbrella stroller',
    upright: 'Upright design',
    uprightCombined: 'Upright design, combined material',
    uprightExpandable: 'Upright design, expandable',
    uprightHard: 'Upright design, hard shell',
    uprightSoft: 'Upright design, soft shell',
    wheelchair: 'Wheelchair and accessories',
    wheeledSportsItem: 'Wheeled sports item',
    white: 'White',
    yellow: 'Yellow',
    yes: 'Yes',
    zipper: 'My bag closes with a zipper',
    zipperBags: 'Bags with a zipper',
    zipperType: 'Zipper type',
};
