// SmartNotify Core -
// const endpoint = 'https://smartnotify-smartsuite-core-dev-api.azurewebsites.net';

// Aer-Lingus Track My Bag -
// const endpoint = 'https://aer-lingus-smartnotify-dev-api.azurewebsites.net';

//Aer-Lingus Report My Bag -
const endpoint = 'https://aer-lingus-smartnotify-reportmybag-prod-api.azurewebsites.net';
const tracerEndpoint = 'https://aer-lingus-smartnotify-reportmybag-prod-tracer-api.azurewebsites.net';

export function getPassengers() {
    return `${endpoint}/api/Events/GetPassengerInfo`;
}

export function getPassengerIdentification({ surname, passengerPNR }) {
    return `${endpoint}/api/Events/GetEncrypedString?surname=${surname}&passengerPNR=${passengerPNR}`;
}

export function submitClaimUri() {
    return `${tracerEndpoint}/api/AhlCreation/CreateTracerRecord`;
}
