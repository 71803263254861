import React from 'react';
import PropTypes from 'prop-types';
import { getDateForLegs } from '../utils/dateFormatUtils';

function getFlightInfo(route) {
    return route.map((leg) => (
        <div
            key={`${leg.flightCarrierCode}${leg.flightNumber}`}
            className="header__flight-info"
        >
            <p className="header__flight-number">
                {`${leg.flightCarrierCode} ${leg.flightNumber},`}
            </p>
            <p className="header__flight-date">
                {getDateForLegs(leg.flightDepartureDateLocal)}
            </p>
        </div>
    ));
}

function renderStationRoute(stations) {
    if (stations.length === 0) {
        return null;
    }

    let stringRoute = [];
    stations.forEach((leg, index) => {
        if (index === 0) {
            stringRoute.push(<span>{leg.flightDepartureStationCode}</span>);
            stringRoute.push(
                <i className="icon-chevron header__flight-route-icon" />
            );
            stringRoute.push(<span>{leg.flightArrivalStationCode}</span>);
            return;
        }
        stringRoute.push(
            <i className="icon-chevron header__flight-route-icon" />
        );
        stringRoute.push(<span>{leg.flightArrivalStationCode}</span>);
    });

    return stringRoute;
}

export default function HeaderInfo(props) {
    if (
        !props.passengerHeaderRoute ||
        props.passengerHeaderRoute.length === 0
    ) {
        return null;
    }

    return (
        <div className="header__route-container">
            <p className="header__route">
                {renderStationRoute(props.passengerHeaderRoute)}
            </p>
            <div className="header__flight-info-container">
                {getFlightInfo(props.passengerHeaderRoute)}
            </div>
        </div>
    );
}

HeaderInfo.propTypes = {
    passengerHeaderRoute: PropTypes.instanceOf(Array),
};

HeaderInfo.defaultProps = {
    passengerHeaderRoute: [],
};
