import React from 'react';
import { Title } from 'smartsuite-ui';

function FormWrapper({ children, instructions, title }) {
    return (
        <div className="form-wrapper">
            <Title cssClassModifiers={['size-medium']}>{title}</Title>
            <span className="form-wrapper__instructions">{instructions}</span>
            <hr className="divider" />
            {children}
        </div>
    );
}

export default FormWrapper;
