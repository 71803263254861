import React, { useState } from 'react';
import {
    ButtonSecondary,
    HorizontalFlex,
    PadContainer,
    VerticalFlex,
} from 'smartsuite-ui';
import { useClaimContext } from '../contexts/ClaimContext';
import claimLabels from '../locales/en/claimLabels';
import ConfirmationModal from './ConfirmationModal';

function FormFooter({ children, navigateToPassengerInformation }) {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const { emptyClaim } = useClaimContext();

    function onCancel() {
        emptyClaim();
        navigateToPassengerInformation();
    }

    return (
        <VerticalFlex>
            <PadContainer cssClassModifiers={['pad-medium']} />
            <PadContainer cssClassModifiers={['pad-small']}>
                <HorizontalFlex cssClassModifiers={['space-between']}>
                    <ButtonSecondary
                        onClick={() => setIsConfirmModalOpen(true)}
                    >
                        {claimLabels.cancel}
                    </ButtonSecondary>
                    <HorizontalFlex>{children}</HorizontalFlex>
                </HorizontalFlex>
                {isConfirmModalOpen && (
                    <ConfirmationModal
                        onConfirm={onCancel}
                        onDismiss={() => setIsConfirmModalOpen(false)}
                    />
                )}
            </PadContainer>
        </VerticalFlex>
    );
}

export default FormFooter;
